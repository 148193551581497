import { Link } from "react-router-dom";

export const UnauthorizedPage: React.FC = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <h2>Det ser ut som det skjedde en feil ved innlogging...</h2>
      <p>
        Vennligst dobbeltsjekk at du har valgt riktig innloggingsmetode og prøv
        igjen. Hvis feilen vedvarer, kontakt Sondre
      </p>
      <Link to="/">Klikk her for å gå tilbake</Link>
    </div>
  );
};
