import { useEffect, useState } from "react";
import { useAuth } from "../../Contexts/AuthContext";
import { IUser } from "../../Types/AuthTypes";
import { RadioButton } from "primereact/radiobutton";
import { InputText } from "primereact/inputtext";
import Allergies from "../Allergies";
import { Button } from "primereact/button";
import axiosInstance from "../../Axios";

type Props = {
  user: IUser | null | undefined;
};

export const RSVP: React.FC<Props> = ({ user }) => {
  const [newUser, setNewUser] = useState<IUser>();

  useEffect(() => {
    if (user) {
      setNewUser(user);
    }
  }, []);

  const updateResponse = async () => {
    const response = await axiosInstance.put("/account/me", newUser);

    setNewUser(response.data);
  };

  return (
    <div
      style={{
        border: "1px solid black",
        borderRadius: 20,
        width: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
      className="rsvp-padding"
    >
      <h1 style={{ marginTop: 0 }}>RSVP</h1>
      <div style={{ display: "flex", gap: 40 }}>
        <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
          <label htmlFor="replyyes" className="ml-2">
            Ja
          </label>
          <RadioButton
            inputId="replyyes"
            name="replyyes"
            value="yes"
            onChange={(e) =>
              setNewUser((prev: IUser | undefined) => ({
                ...prev!,
                isComing: true,
              }))
            }
            checked={newUser?.isComing === true}
          />
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
          <label htmlFor="replyno" className="ml-2">
            Nei
          </label>
          <RadioButton
            inputId="replyno"
            name="replyno"
            value="no"
            onChange={(e) =>
              setNewUser((prev: IUser | undefined) => ({
                ...prev!,
                isComing: false,
              }))
            }
            checked={newUser?.isComing === false}
          />
        </div>
      </div>
      <div style={{ width: "100%", marginBottom: 20 }}>
        <label htmlFor="name" style={{ fontSize: 30 }}>
          Navn
        </label>
        <InputText
          id="name"
          value={newUser?.name}
          placeholder="Navn"
          style={{ width: "100%", backgroundColor: "#CFB09566" }}
          onChange={(e) =>
            setNewUser((prev: IUser | undefined) => ({
              ...prev!,
              name: e.target.value,
            }))
          }
        />
      </div>
      <Allergies
        onChange={(allergies) =>
          setNewUser((prev: IUser | undefined) => ({
            ...prev!,
            allergies: allergies,
          }))
        }
        initialAllergies={newUser?.allergies}
      ></Allergies>

      <Button
        style={{
          backgroundColor: "#cfb09566",
          border: "1px solid black",
          color: "black",
          marginTop: 20,
        }}
        onClick={updateResponse}
      >
        Send
      </Button>
    </div>
  );
};
