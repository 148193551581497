import { Button } from "primereact/button";
import React, { useEffect, useState } from "react";
import { Map } from "../../Components/Map";

export const EasterHome: React.FC = () => {
  const [position, setPosition] = useState<{
    latitude: null | number;
    longitude: null | number;
  }>({ latitude: null, longitude: null });
  const [varNavigatior, setVarNavigator] = useState<string[]>([]);

  const getLocation = () => {
    setVarNavigator(Object.keys(navigator));
    console.log(navigator);
    if ("geolocation" in navigator) {
      navigator.geolocation.watchPosition((i) => console.log(i));
      navigator.geolocation.getCurrentPosition(function (position) {
        setPosition({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      });
    } else {
      console.log("Geolocation is not available in your browser.");
    }
  };

  useEffect(() => {
    getLocation();
  }, []);

  //

  return (
    <div>
      <h1>Eggjakt!</h1>

      <p>
        Det kreves posisjonstilgang for å gjennomføre eggjakten. Vennligst
        tillat dette.
      </p>
      {varNavigatior.map((i) => i)}
      <Button type="button" onClick={() => getLocation()}>
        Slå på
      </Button>

      {position.latitude && position.longitude ? (
        <>
          <p>
            Latitude: {position.latitude}, Longitude: {position.longitude}
          </p>
          <div style={{ height: 1000 }}>
            <Map
              centerLocation={[59.1475197, 6.0756453]}
              zoom={20}
              markers={[
                {
                  position: [59.1475197, 6.0756453],
                  label: "Egg 1",
                },
              ]}
            ></Map>
          </div>
        </>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};
