import axios from 'axios';

// Create an instance of axios with default properties
const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL || 'https://localhost:7129/', // Fallback URL if the env variable is not set
    withCredentials: true,
    maxRedirects: 0
    // Other default configurations can go here
});

// Set up an interceptor to inject the auth token into headers of each request
axiosInstance.interceptors.request.use(config => {
    // const token = localStorage.getItem('authToken'); // Replace with your token retrieval logic
    // config.headers.Authorization =  token ? `Bearer ${token}` : '';
    return config;
});

export default axiosInstance;