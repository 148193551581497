import React, { ChangeEvent, useEffect, useState } from "react";
import { Galleria } from "primereact/galleria";
import { ImageDataType } from "../../Types/Image";
import ImageGrid from "../../Components/ImageDisplay";
import axiosInstance from "../../Axios";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { ToggleButton, ToggleButtonChangeEvent } from "primereact/togglebutton";
import { InputText } from "primereact/inputtext";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { Button } from "primereact/button";
import { MultiSelect } from "primereact/multiselect";
import { DeleteForever, Visibility, VisibilityOff } from "@mui/icons-material";

type UserNameOption = {
  label: string;
  value: string;
};

export const AdminImageComponent: React.FC = () => {
  const [images, setImages] = useState<ImageDataType[]>([]);
  const [filteredImages, setFilteredImages] = useState<ImageDataType[]>([]);
  const [showFiltered, setShowFiltered] = useState<boolean>(true);
  const [selectedUserNames, setSelectedUserNames] = useState<string[]>([]);
  const [isDeleted, setIsDeleted] = useState<boolean>(false);
  const [isHearted, setIsHearted] = useState<boolean>(false);
  const [sortDateDescending, setSortDateDescending] = useState<boolean>(false);

  const [userNames, setUserNames] = useState<UserNameOption[]>([]);

  useEffect(() => {
    setFilteredImages(images);
    const uniqueUserNames: UserNameOption[] = Array.from(
      new Set(images.map((image) => image.userName))
    ).map((userName) => ({
      label: userName,
      value: userName,
    }));
    setUserNames(uniqueUserNames);
  }, [images]);

  useEffect(() => {
    let tempImages = images;

    if (showFiltered) {
      tempImages = tempImages.filter(
        (image) =>
          (isDeleted ? image.isDeleted : true) &&
          (isHearted ? image.isHearted : true) &&
          (selectedUserNames.length
            ? selectedUserNames.includes(image.userName)
            : true)
      );
    }

    // Sort images based on timestamp
    tempImages = tempImages.sort((a, b) =>
      sortDateDescending
        ? new Date(b.uploadedTime).getTime() -
          new Date(a.uploadedTime).getTime()
        : new Date(a.uploadedTime).getTime() -
          new Date(b.uploadedTime).getTime()
    );

    setFilteredImages(tempImages);
  }, [
    images,
    showFiltered,
    selectedUserNames,
    isDeleted,
    isHearted,
    sortDateDescending,
  ]);

  const toggleSortOrder = () => {
    setSortDateDescending(!sortDateDescending);
  };

  const hideImage = async (image: ImageDataType) => {
    try {
      const response = await axiosInstance.put(`images/${image.rowKey}/hide`);
      console.log("Image hidden successfully:", response.data);
      setImages((prevImages) =>
        prevImages.map((prevImage) =>
          prevImage.rowKey === image.rowKey
            ? { ...prevImage, hide: true }
            : prevImage
        )
      );
    } catch (error) {
      console.error("Error hiding image:", error);
    }
  };

  const showImage = async (image: ImageDataType) => {
    try {
      const response = await axiosInstance.put(`images/${image.rowKey}/show`);
      console.log("Image shown successfully:", response.data);
      setImages((prevImages) =>
        prevImages.map((prevImage) =>
          prevImage.rowKey === image.rowKey
            ? { ...prevImage, hide: false }
            : prevImage
        )
      );
    } catch (error) {
      console.error("Error showing image:", error);
    }
  };

  const deleteImage = async (image: ImageDataType) => {
    try {
      const response = await axiosInstance.delete(`images/${image.rowKey}`);
      console.log("Image deleted successfully:", response.data);
      setImages((prevImages) =>
        prevImages.filter((prevImage) => prevImage.rowKey !== image.rowKey)
      );
    } catch (error) {
      console.error("Error deleting image:", error);
    }
  };

  useEffect(() => {
    const fetchImages = async () => {
      const response = await axiosInstance.get<ImageDataType[]>("images");
      response.data[2].userName = "Tamira";
      setImages(response.data);
    };

    fetchImages();
  }, []);

  const responsiveOptions = [
    {
      breakpoint: "991px",
      numVisible: 4,
    },
    {
      breakpoint: "767px",
      numVisible: 3,
    },
    {
      breakpoint: "575px",
      numVisible: 3,
    },
  ];

  const itemTemplate = (image: ImageDataType) => {
    return (
      //   <img
      //     src={item.url}
      //     alt={item.userId}
      //     style={{
      //       width: 600,
      //       maxHeight: 450,
      //       height: 450,
      //       display: "block",
      //       overflow: "hidden",
      //       objectFit: "scale-down",
      //     }}
      //   />
      <div
        style={{
          maxHeight: 450,
          height: 450,
        }}
      >
        {image.type === "video" ? (
          <video
            src={image.url}
            controls
            style={{
              width: "100%",
              height: "100%",
              objectFit: "scale-down",

              opacity: image.hide ? 0.5 : 1,
            }}
          />
        ) : (
          <img
            src={image.url}
            alt={`Image`}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "scale-down",
              opacity: image.hide ? 0.5 : 1,
            }}
          />
        )}
      </div>
    );
  };

  const thumbnailTemplate = (item: ImageDataType) => {
    return (
      <div style={{ position: "relative", display: "inline-block" }}>
        {item.hide && (
          <div
            style={{
              position: "absolute",
              backgroundColor: "gray",
              borderRadius: 50,
              padding: 3,
              width: 20,
              height: 20,
              color: "rgba(255, 255, 255, 0.8)", // Example styling, adjust as needed
              top: 5, // Adjust based on your needs
              left: 5, // Adjust based on your needs
              zIndex: 1, // Ensure it's above the image
            }}
          >
            <VisibilityOff fontSize={"small"} />
          </div>
        )}
        <img
          src={item.url}
          alt={item.userId}
          style={{
            display: "block",
            maxHeight: 100,
            maxWidth: 100,
            overflow: "hidden",
            objectFit: "scale-down",
          }}
        />
      </div>
    );
  };

  const heartImage = async (rowKey: string) => {
    try {
      const response = await axiosInstance.post(`images/${rowKey}/heart`);
      console.log("Image hearted successfully:", response.data);
      setImages((prevImages) =>
        prevImages.map((prevImage) =>
          prevImage.rowKey === rowKey
            ? { ...prevImage, isHearted: !prevImage.isHearted }
            : prevImage
        )
      );
    } catch (error) {
      console.error("Error hearting image:", error);
    }
  };

  const caption = (item: ImageDataType) => {
    return (
      <React.Fragment>
        <FavoriteIcon
          style={{ color: item.isHearted ? "red" : "white", cursor: "pointer" }}
          onClick={() => heartImage(item.rowKey)}
        />
        <DeleteForever
          style={{ color: "white", cursor: "pointer" }}
          onClick={() => deleteImage(item)}
        />
        {item.hide ? (
          <Visibility
            style={{ cursor: "pointer" }}
            onClick={() => showImage(item)}
          ></Visibility>
        ) : (
          <VisibilityOff
            style={{ cursor: "pointer" }}
            onClick={() => hideImage(item)}
          ></VisibilityOff>
        )}
      </React.Fragment>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          marginBottom: "1rem",
        }}
      >
        <MultiSelect
          value={selectedUserNames}
          options={userNames}
          onChange={(e) => setSelectedUserNames(e.value)}
          placeholder="Select Usernames"
          display="chip"
        />
        <ToggleButton
          checked={isHearted}
          onChange={(e) => setIsHearted(e.value)}
          onLabel="Hearted"
          offLabel="Not Hearted"
        />
        <ToggleButton
          checked={sortDateDescending}
          onLabel="Sort Date Ascending"
          offLabel="Sort Date Descending"
          onChange={toggleSortOrder}
        />
        {/* <ToggleButton
          checked={showFiltered}
          onChange={(e) => setShowFiltered(e.value)}
          onLabel="Show Filtered"
          offLabel="Show Original"
        /> */}
      </div>
      <Galleria
        value={filteredImages}
        numVisible={7}
        circular
        showItemNavigators
        style={{ maxWidth: "800px" }}
        item={itemTemplate}
        thumbnail={thumbnailTemplate}
        showThumbnailNavigators
        showThumbnails
        caption={caption}
        responsiveOptions={responsiveOptions}
      />
    </div>
  );
};
