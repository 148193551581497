import React, { useState, useEffect } from "react";
import { Chip } from "primereact/chip";
import {
  AutoComplete,
  AutoCompleteCompleteEvent,
} from "primereact/autocomplete";

// Predefined list of common allergies in Norwegian
export const COMMON_ALLERGIES = [
  "Gluten",
  "Skalldyr",
  "Egg",
  "Fisk",
  "Peanøtter",
  "Soya",
  "Melk",
  "Nøtter",
  "Selleri",
  "Sennep",
  "Sesamfrø",
  "Svoveldioksid og sulfitter",
  "Lupin",
  "Bløtdyr",
];

interface AllergiesProps {
  initialAllergies?: string; // Comma-separated allergies string
  onChange: (allergiesString: string) => void; // Callback function to handle changes
}

export const Allergies: React.FC<AllergiesProps> = ({
  initialAllergies,
  onChange,
}) => {
  const [allergies, setAllergies] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState("");
  const [filteredAllergies, setFilteredAllergies] = useState<string[]>([]);

  // Load initial allergies
  useEffect(() => {
    if (initialAllergies) {
      setAllergies(initialAllergies.split(","));
    }
  }, [initialAllergies]);

  //   useEffect(() => {
  //     onChange?.(allergies.join(","));
  //   }, [allergies, onChange]);

  const handleAddAllergy = (allergy: string) => {
    if (allergy && !allergies.includes(allergy)) {
      setAllergies((prev) => [...prev, allergy]);
      setSearchValue("");
    }
  };

  const handleDeleteAllergy = (allergy: string) => {
    setAllergies(allergies.filter((a) => a !== allergy));
  };

  const search = (event: AutoCompleteCompleteEvent) => {
    let _filteredAllergies;

    if (!event.query.trim().length) {
      _filteredAllergies = [
        ...COMMON_ALLERGIES.filter((i) => !allergies.includes(i)),
      ];
    } else {
      _filteredAllergies = COMMON_ALLERGIES.filter((allergy) => {
        if (allergies.includes(allergy)) return;
        return allergy.toLowerCase().startsWith(event.query.toLowerCase());
      });
    }

    setFilteredAllergies(_filteredAllergies);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <label htmlFor="allergies" style={{ fontSize: 30 }}>
        Allergier
      </label>
      <AutoComplete
        id="allergies"
        multiple
        value={allergies}
        suggestions={filteredAllergies}
        dropdown
        style={{ width: "100%" }}
        completeMethod={search}
        onChange={(e) => {
          onChange(e.value.join(",").trim());
          setAllergies(e.value);
        }}
      />
    </div>
  );
};

export default Allergies;
