import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMapEvents,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { ReactNode, useRef, useState } from "react";
import L, { LatLng, LatLngExpression, Polyline } from "leaflet";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

type Props = {
  height?: string | number;
  width?: string | number;
  centerLocation?: LatLngExpression;
  markers?: { position: LatLngExpression; label?: string }[];
  zoom?: number;
  showLayer?: boolean;
  lines?: ReactNode[];
  terrain?: boolean;
};

export const Map: React.FC<Props> = ({
  height = "100%",
  width = "100%",
  centerLocation = [58.9285782, 5.8485433],
  markers = [
    {
      position: [58.9262829, 5.8510551],
      label: "Kirken",
    },
    {
      position: [58.9308714, 5.8464185],
      label: "Båthuset",
    },
  ],
  zoom = 15,
  showLayer = true,
  lines,
  terrain = true,
}) => {
  const mapRef = useRef<L.Map | null>(null);
  const [position, setPosition] = useState<LatLng | null>(null);

  const LocationFinderDummy = () => {
    const map = useMapEvents({
      click(e) {
        console.log(e.latlng);
      },
    });
    return null;
  };

  const LocationMarker = () => {
    const map = useMapEvents({
      click() {
        map.locate();
      },
      locationfound(e) {
        console.log("Map click", e);
        setPosition(e.latlng);
        map.flyTo(e.latlng, map.getZoom());
      },
    });

    return position === null ? null : (
      <Marker position={position} icon={PositionIcon}>
        <Popup>You are here</Popup>
      </Marker>
    );
  };

  return (
    <>
      <MapContainer
        center={centerLocation}
        zoom={zoom}
        ref={mapRef}
        style={{
          height: height,
          width: width,
          borderRadius: 20,
          border: "1px solid black",
        }}
        scrollWheelZoom
        zoomControl={false}
      >
        {showLayer && (
          <TileLayer
            url={
              terrain
                ? "http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                : "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            }
          />
        )}
        <LocationFinderDummy></LocationFinderDummy>
        {/* Additional map layers or components can be added here */}
        {markers.map((marker, index) => (
          <Marker
            key={index}
            position={marker.position}
            icon={
              marker.label === "You"
                ? PositionIcon
                : marker.label === "Target"
                ? TargetIcon
                : Icon
            }
          >
            <Popup>{marker.label}</Popup>
            {/* <LocationMarker></LocationMarker> */}
          </Marker>
        ))}

        {lines}
      </MapContainer>
    </>
  );
};

let Icon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
});

let PositionIcon = L.icon({
  iconUrl: "/images/easter/blue_circle.png",
  iconSize: [16, 16],
  iconAnchor: [8, 8],
  popupAnchor: [0, 0],
});

let TargetIcon = L.icon({
  iconUrl: "/images/easter/red_circle.png",
  iconSize: [16, 16],
  iconAnchor: [8, 8],
  popupAnchor: [0, 0],
});
