import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Input,
  InputLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAuth } from "../Contexts/AuthContext";
import ImageUploader from "../Components/ImageUploader";
import ImageGrid from "../Components/ImageDisplay";
import { Map } from "../Components/Map";
import { Link, useNavigate } from "react-router-dom";
import { Menubar } from "primereact/menubar";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { MenuItem } from "primereact/menuitem";
import { RSVP } from "../Components/Home/RSVP";
import { Countdown } from "../Components/Home/Countdown";
import { Timeline } from "primereact/timeline";
import { WeddingTimeline } from "../Components/Home/Timeline";
import { BridalParty } from "../Components/Home/BridalParty";

export const HomePage: React.FC = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const getGreeting = () => {
    const date = new Date();
    const hours = date.getHours();
    if (hours < 12) {
      return "God morgen";
    } else if (hours < 18) {
      return "God ettermiddag";
    } else {
      return "God kveld";
    }
  };

  return (
    <div>
      <Menubar
        model={
          [
            {
              label: "RSVP",
              command: () => navigate("#rsvp"),
            },
            {
              label: "Informasjon",
              command: () => navigate("#rsvp"),
            },
            {
              label: "Dagen vår",
              command: () => navigate("#rsvp"),
            },
            {
              label: "Gaveønsker",
              command: () => navigate("#rsvp"),
              className: "right-align",
            },
            {
              label: "Kleskode",
              command: () => navigate("#rsvp"),
              className: "right-align",
            },
            {
              icon: <FileUploadIcon></FileUploadIcon>,
              command: () => navigate("#rsvp"),
              className: "right-align",
            },
            user?.isAdmin && {
              label: "Admin",
              command: () => navigate("/admin/home"),
            },
          ].filter((i) => i !== null && i !== undefined) as MenuItem[]
        }
      >
        <p>Foo</p>
      </Menubar>
      {/* Header */}
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          overflow: "hidden",
          backgroundImage: "url('/images/login_background.jpg')",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        className=""
      >
        <img
          src="/images/wedding_logo_512x512.png"
          height={"50%"}
          style={{ width: "auto", objectFit: "scale-down" }}
        ></img>
      </div>
      <div
        style={{ width: "100%", height: 20, backgroundColor: "#CFB09566" }}
      ></div>
      <div className="home-content">
        <div className="section-1" id="rsvp">
          <div className="content-1">
            <RSVP user={user}></RSVP>
          </div>
          <div className="content-2">
            <Countdown></Countdown>
          </div>
        </div>
        <div className="section-2" style={{ marginTop: 50 }}>
          <div className="content-1">
            <div style={{ textAlign: "center" }}>
              <h1 style={{ fontFamily: "Italiana", marginTop: 0 }}>
                Informasjon
              </h1>
              <p style={{ fontFamily: "Italiana" }}>
                Tamira og Sondre gifter seg i Riska Gamle Kirke
                <br />
                <br />
                Vielsen starter kl 13:30
                <br />
                viktig å være der 15 minutter før
                <br />
                <br />
                Etter vielsen blir det velkomst og bryllupskake på Båthuset, der
                festen skal være.
                <br />
                <br />
                Deretter reiser brudeparet med følge til bildetaking. Brudeparet
                ankommer lokalet ca. kl 16:30
                <br />
                <br />
                Skal du holde tale eller lignende, ta kontakt med toastmaster.
                Kontakt informasjon lengre nede
              </p>
            </div>
          </div>
          <div className="content-2">
            <Map></Map>
          </div>
        </div>
        <div className="section-3">
          <WeddingTimeline></WeddingTimeline>
          <div
            style={{
              marginTop: 50,
              marginBottom: 50,

              paddingLeft: 20,
              paddingRight: 20,
            }}
          >
            <div
              style={{
                height: 2,
                backgroundColor: "black",
                borderRadius: 10,
              }}
            ></div>
          </div>
        </div>
        <div className="section-4">
          <div className="content-1">
            <BridalParty></BridalParty>
          </div>
          <div className="content-2">
            <div className="subcontent-1"></div>
            <div className="subcontent-2"></div>
          </div>
        </div>
      </div>
    </div>
    // <Stack>
    //   <Stack direction={"row"} justifyContent={"space-between"}>
    //     <div
    //       style={{
    //         padding: 20,
    //         borderRadius: 30,
    //         backgroundColor: "#EED9C4F7",
    //         marginTop: 15,
    //         marginLeft: 10,
    //         width: "fit-content",
    //       }}
    //     >
    //       <p style={{ margin: 0, fontSize: 30, fontWeight: 400 }}>
    //         {getGreeting().toUpperCase()},{" "}
    //         {user?.name?.split(" ")[0]?.toUpperCase()}
    //       </p>
    //     </div>
    //     {user?.isAdmin && <Link to="/admin/home">Admin</Link>}
    //   </Stack>
    //   <Stack style={{ height: "40vh" }}>
    //     <img
    //       src="/images/wedding_logo_512x512.png"
    //       height={"100%"}
    //       style={{ width: "auto", objectFit: "scale-down" }}
    //     ></img>
    //   </Stack>
    //   <Stack alignItems={"center"}>
    //     <div
    //       style={{
    //         padding: 20,
    //         borderRadius: 30,
    //         backgroundColor: "#EED9C4F7",
    //         marginTop: 15,
    //         marginLeft: 10,
    //         width: "fit-content",
    //       }}
    //     >
    //       <p style={{ margin: 0, fontSize: 50, fontWeight: 400 }}>
    //         VI VIL GJERNE DELE DAGEN VÅR MED DEG
    //       </p>
    //     </div>
    //     <p style={{ margin: 0, fontSize: 50, fontWeight: 400 }}>KOMMER DU?</p>
    //     <FormControl>
    //       <RadioGroup
    //         aria-labelledby="demo-radio-buttons-group-label"
    //         name="radio-buttons-group"
    //         style={{ flexDirection: "row" }}
    //       >
    //         <FormControlLabel
    //           value="ja"
    //           control={
    //             <Radio
    //               sx={{
    //                 color: "#EED9C4",
    //                 ".MuiSvgIcon-root": {
    //                   backgroundColor: "#EED9C4",
    //                   borderRadius: 50,
    //                 },
    //                 "&.Mui-checked": {
    //                   color: "black",
    //                 },
    //               }}
    //             />
    //           }
    //           label="JA"
    //         />
    //         <FormControlLabel
    //           value="nei"
    //           control={
    //             <Radio
    //               sx={{
    //                 color: "#EED9C4",
    //                 ".MuiSvgIcon-root": {
    //                   backgroundColor: "#EED9C4",
    //                   borderRadius: 50,
    //                 },
    //                 "&.Mui-checked": {
    //                   color: "black",
    //                 },
    //               }}
    //             />
    //           }
    //           label="NEI"
    //         />
    //       </RadioGroup>
    //     </FormControl>
    //     <Stack>
    //       <Stack direction={"row"} gap={30}>
    //         <FormControl variant="filled">
    //           <label htmlFor="name">Navn</label>
    //           <TextField id="name"></TextField>
    //         </FormControl>
    //         <FormControl variant="standard">
    //           <label htmlFor="surname">Etternavn</label>
    //           <TextField id="surname"></TextField>
    //         </FormControl>
    //       </Stack>
    //     </Stack>
    //   </Stack>
    //   <Map></Map>
    //   <div style={{ margin: "20px 10%" }}>
    //     <ImageGrid></ImageGrid>
    //   </div>
    // </Stack>
  );
};
