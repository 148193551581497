import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

export const EasterPhoneGame: React.FC = () => {
  const [input, setInput] = useState<string>("");
  const correctAnswer = "denne telefonen er gammel og ødelagt - skaff en ny";

  const [answeredCorrectly, setAnsweredCorrectly] = useState<boolean>(false);
  const navigate = useNavigate();

  const checkAnswer = () => {
    if (
      input.replaceAll(" ", "").toLowerCase() ==
        correctAnswer.replaceAll(" ", "").toLowerCase() ||
      input.replaceAll(" ", "").replaceAll("-", "").toLowerCase() ==
        correctAnswer.replaceAll(" ", "").replaceAll("-", "").toLowerCase()
    ) {
      setAnsweredCorrectly(true);
    } else {
      alert("Feil svar, prøv igjen :)");
    }
  };

  const onCorrectAnswer = () => {
    navigate("/easter/map4qoiwej");
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        maxWidth: "100vw",
      }}
    >
      <img src="/images/easter/phone.jpeg" style={{ maxWidth: "100%" }}></img>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 5,
          textAlign: "center",
        }}
      >
        <h3>Svar</h3>
        <InputText
          disabled={answeredCorrectly}
          defaultValue={input}
          onChange={(e) => setInput(e.target.value)}
        ></InputText>
        {!answeredCorrectly ? (
          <Button type="button" onClick={() => checkAnswer()}>
            Sjekk svar
          </Button>
        ) : (
          <>
            <Button
              type="button"
              severity="success"
              onClick={() => onCorrectAnswer()}
            >
              Bra jobba! Gå videre
            </Button>
          </>
        )}
      </div>
    </div>
  );
};
