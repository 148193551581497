import React, { useCallback, useRef, ReactNode } from "react";
import ToastContext from "./ToastContext";
import { Toast, ToastMessage } from "primereact/toast";

interface ToastProviderProps {
  children: ReactNode;
}

export const ToastProvider: React.FC<ToastProviderProps> = ({ children }) => {
  const toastRef = useRef<Toast>(null);

  const showToast = useCallback((message: ToastMessage) => {
    if (toastRef.current) {
      toastRef.current.show(message);
    } else {
      console.error("Toast ref is not initialized");
    }
  }, []);

  return (
    <ToastContext.Provider value={{ showToast }}>
      <Toast ref={toastRef} />
      {children}
    </ToastContext.Provider>
  );
};
