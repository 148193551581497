import React from "react";
import { useAuth } from "../../Contexts/AuthContext";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { Menubar } from "primereact/menubar";
import { MenuItem } from "@mui/material";
import { HashLoader } from "react-spinners";

type Props = {
  children?: React.ReactNode;
};

export const AdminPage: React.FC<Props> = ({ children }) => {
  const { user, verifyUser } = useAuth();
  const navigate = useNavigate();

  if (user === undefined)
    return (
      <HashLoader
        color={"#123abc"}
        loading={true}
        size={150}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    );

  if (!user?.isAdmin) return <Navigate to={"/"}></Navigate>;

  return (
    <div>
      <Menubar
        model={[
          {
            command: () => navigate("/admin/home"),
            label: "Hjem",
          },
          {
            command: () => navigate("/admin/users"),
            label: "Brukere",
          },
          {
            command: () => navigate("/admin/invitations"),
            label: "Invitasjoner",
          },
          {
            command: () => navigate("/admin/images"),
            label: "Bilder",
          },
          {
            command: () => navigate("/admin/email"),
            label: "Varsler",
          },
          {
            command: () => navigate("/"),
            label: "Tilbake til nettsiden",
          },
        ]}
      />
      <Outlet />
    </div>
  );
};
