import React, { useEffect, useState } from "react";
import { Chart } from "primereact/chart";
import { IUser } from "../../Types/AuthTypes";
import axiosInstance from "../../Axios";
import { Invitation } from "../../Types/Invitation";
import { RoundChart } from "../../Components/Chart";
import { COMMON_ALLERGIES } from "../../Components/Allergies";

type AllergyChartData = {
  data: number[];
  labels: string[];
};

export const AdminHomePage: React.FC = () => {
  const [users, setUsers] = useState<IUser[]>([]);
  const [allergyData, setAllergyData] = useState<AllergyChartData>({
    data: [],
    labels: [],
  });

  useEffect(() => {
    const getUsers = async () => {
      const response = await axiosInstance.get<IUser[]>("/admin/users");
      setUsers(response?.data);
    };

    getUsers();
  }, []);

  useEffect(() => {
    const processAllergies = () => {
      const allergyCategories = COMMON_ALLERGIES.map((a) =>
        a.toLowerCase().trim()
      );

      let allergyCounts: { [key: string]: number } = allergyCategories.reduce(
        (acc, category) => ({ ...acc, [category]: 0 }),
        {}
      );
      allergyCounts["No Specified Allergies"] = 0;

      users.forEach((user) => {
        if (user.isComing) {
          const userAllergies = user.allergies
            ? user.allergies.split(",").map((a) => a.trim().toLowerCase())
            : [];
          const found = userAllergies.some((allergy) => {
            if (allergyCategories.includes(allergy)) {
              allergyCounts[allergy]++;
              return true;
            }
            return false;
          });

          if (!found && userAllergies.length > 0) {
            allergyCounts["Other Allergies"] =
              (allergyCounts["Other Allergies"] || 0) + 1;
          } else if (!found) {
            allergyCounts["No Specified Allergies"]++;
          }
        }
      });

      // Filter out labels and data with 0 count
      const filteredData: number[] = [];
      const filteredLabels: string[] = [];
      Object.entries(allergyCounts).forEach(([label, count]) => {
        if (count > 0) {
          filteredLabels.push(label);
          filteredData.push(count);
        }
      });

      setAllergyData({
        data: filteredData,
        labels: filteredLabels,
      });
    };

    if (users.length > 0) {
      processAllergies();
    }
  }, [users]);
  return (
    // If screen size is small, it should be column instead of row

    <div
      style={{
        display: "flex",
        maxHeight: 300,
        flexDirection: window.innerWidth < 768 ? "column" : "row",
      }}
    >
      <RoundChart
        label="Brukere og invitasjoner"
        type="doughnut"
        data={[
          users.filter((i) => i.signupDate).length,
          users.filter((i) => !i.signupDate).length,
        ]}
        labels={["Registrerte brukere", "Ikke pålogget"]}
      />
      <RoundChart
        label="Svar på invitasjoner"
        type="doughnut"
        data={[
          users.filter((i) => i.isComing === true).length,
          users.filter((i) => i.isComing === false).length,
          users.filter((i) => i.isComing === undefined || i.isComing === null)
            .length,
        ]}
        labels={["Har svart ja", "Har svart nei", "Har ikke svart"]}
      />

      <RoundChart
        label="Allergier blant de som kommer"
        type="doughnut"
        data={allergyData.data}
        labels={allergyData.labels}
      />
    </div>
  );
};
