import {
  MultiStateCheckbox,
  MultiStateCheckboxOption,
} from "primereact/multistatecheckbox";
import React, { useEffect, useState } from "react";

interface Checkbox {
  id: string;
  isChecked: "none" | "check" | "x";
}

interface Category {
  id: string;
  grid: Checkbox[][];
}

interface PuzzleGridProps {
  categories: number;
  gridSize: number;
  rowLabels: string[];
  columnLabels: string[];
}

const options: MultiStateCheckboxOption[] = [
  {
    value: "check",
    icon: "pi pi-check",
    className: "p-checkbox-check",
    style: { backgroundColor: "green" },
  },
  {
    value: "x",
    icon: "pi pi-times",
    className: "p-checkbox-check",
    style: { backgroundColor: "red" },
  },
];

const generateInitialCategory = (
  categoryId: string,
  gridSize: number
): Category => {
  let grid: Checkbox[][] = [];
  for (let i = 0; i < gridSize; i++) {
    let row: Checkbox[] = [];
    for (let j = 0; j < gridSize; j++) {
      row.push({ id: `${categoryId}-${i}-${j}`, isChecked: "none" });
    }
    grid.push(row);
  }
  return { id: categoryId, grid };
};

const generateInitialGrid = (
  categories: number,
  gridSize: number
): Category[][] => {
  let grid: Category[][] = [];
  for (let i = 0; i < categories; i++) {
    let row: Category[] = [];
    for (let j = 0; j < categories - i; j++) {
      row.push(generateInitialCategory(`category-${i}-${j}`, gridSize));
    }
    grid.push(row);
  }
  console.log(grid);
  return grid;
};

export const EasterLogicPuzzle: React.FC<PuzzleGridProps> = ({
  categories,
  gridSize,
  columnLabels,
  rowLabels,
}) => {
  const [grid, setGrid] = useState<Category[][]>(
    []
  );

  const toggleCheckbox = (
    categoryRow: number,
    categoryIndex: number,
    rowIndex: number,
    columnIndex: number,
    value: Checkbox["isChecked"]
  ) => {
    const newGrid = grid.map((row, rIndex) => {
      if (rIndex === categoryRow) {
        return row.map((category, cIndex) => {
          if (cIndex === categoryIndex) {
            let newCategory = { ...category };
            // newCategory.grid[rowIndex][columnIndex].isChecked =
            //   !newCategory.grid[rowIndex][columnIndex].isChecked;
            newCategory.grid[rowIndex][columnIndex].isChecked = value;
            return newCategory;
          }
          return category;
        });
      }
      return row;
    });
    setGrid(newGrid);
  };


  const saveToLocalStorage = (data: Category[][]) => {
    try {
      const serializedData = JSON.stringify(data);
      localStorage.setItem("easterGridPuzzleData", serializedData);
    } catch (err) {
      console.error("Could not save to local storage.", err);
    }
  };

  // Function to load data from local storage
  const loadFromLocalStorage = () => {
    try {
      const serializedData = localStorage.getItem("easterGridPuzzleData");
      console.log(serializedData)
      return serializedData
        ? JSON.parse(serializedData)
        : generateInitialGrid(categories, gridSize); // Default data if nothing is in local storage
    } catch (err) {
      console.error("Could not load from local storage.", err);
      return []; // Return default state in case of an error
    }
  };

  // Effect to load data from local storage on mount
  useEffect(() => {
    const loadedData = loadFromLocalStorage();
    setGrid(loadedData);
  }, []); // Empty dependency array means this effect runs once on mount

  // Effect to save data to local storage whenever it changes
  useEffect(() => {
    console.log("Saving data to local storage");
    if (grid.length > 0) saveToLocalStorage(grid);
  }, [grid]);

  return (
    <div className="grid-container">
      <div className="row-item">
        <div className="row-labels">
          {rowLabels.map((i, index) => (
            <p
              className={(index + 1) % gridSize === 0 ? "row-label-space" : ""}
            >
              {i}
            </p>
          ))}
        </div>
      </div>
      <div className="column-item">
        <div className="column-labels">
          {columnLabels.map((i, index) => (
            <p
              className={
                (index + 1) % gridSize === 0 ? "column-label-space" : ""
              }
            >
              {i}
            </p>
          ))}
        </div>
      </div>
      <div className="big-square">
        {grid.map((categoryRow, categoryRowIndex) => (
          <div
            key={categoryRowIndex}
            style={{ display: "flex", marginBottom: "20px" }}
          >
            {categoryRow.map((category, categoryIndex) => (
              <div
                key={category.id}
                style={{
                  marginRight: "20px",
                  gap: 5,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {category.grid.map((row, rowIndex) => (
                  <div
                    key={`${category.id}-row-${rowIndex}`}
                    style={{ display: "flex", gap: 5 }}
                  >
                    {row.map((checkbox) => (
                      <label key={checkbox.id}>
                        <MultiStateCheckbox
                          style={{
                            width: 20,
                            height: 20,
                          }}
                          pt={{
                            checkbox: {
                              width: 20,
                              height: 20,
                            },
                          }}
                          value={checkbox.isChecked}
                          onChange={(e) =>
                            toggleCheckbox(
                              categoryRowIndex,
                              categoryIndex,
                              rowIndex,
                              parseInt(checkbox.id.split("-")[4]),
                              e.value
                            )
                          }
                          options={options}
                          optionValue="value"
                        />
                      </label>
                    ))}
                  </div>
                ))}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};
