import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const EasterMatchesGame: React.FC = () => {
  const [input, setInput] = useState<string>("");
  const correctAnswer = "3x6=18";

  const [answeredCorrectly, setAnsweredCorrectly] = useState<boolean>(false);
  const navigate = useNavigate();

  const [showHint, setShowHint] = useState<
    {
      id: number;
      isShown: boolean;
      minutes: number;
      showButton: boolean;
      message: string;
    }[]
  >([
    {
      id: 1,
      isShown: false,
      minutes: 4,
      showButton: false,
      message: "Det er kun tallene det skal endres på",
    },
    {
      id: 2,
      isShown: false,
      minutes: 6,
      showButton: false,
      message:
        "Alle tallene (men ikke nødvendigvis alle sifferne) skal endres på",
    },
    {
      id: 3,
      isShown: false,
      minutes: 7,
      showButton: false,
      message: "Den ene fyrstikken skal flyttes internt på to-tallet",
    },
    {
      id: 4,
      isShown: false,
      minutes: 8,
      showButton: false,
      message: "Den andre fyrstikken skal flyttes fra åtte-tallet til svaret",
    },
  ]);

  useEffect(() => {
    const intervals: NodeJS.Timeout[] = [];
    showHint.forEach((hint) => {
      const interval = setTimeout(() => {
        setShowHint((currentHints) =>
          currentHints.map((currentHint) =>
            currentHint.id === hint.id
              ? { ...currentHint, showButton: true }
              : currentHint
          )
        );
      }, hint.minutes * 60000);

      intervals.push(interval);
    });

    return () => {
      intervals.forEach((interval) => clearInterval(interval));
    };
  }, []);

  const checkAnswer = () => {
    if (
      input.replaceAll(" ", "").replaceAll("*", "x").toLowerCase() ===
      correctAnswer.toLowerCase()
    ) {
      setAnsweredCorrectly(true);
    } else {
      alert("Feil svar, prøv igjen :)");
    }
  };

  const onCorrectAnswer = () => {
    navigate("/easter/map3wejfwnj");
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        maxWidth: "100vw",
      }}
    >
      <img src="/images/easter/matches.jpg" style={{ maxWidth: "100%" }}></img>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 5,
          textAlign: "center",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          {showHint
            .sort((a, b) => a.id - b.id)
            .map((i) =>
              i.showButton && !i.isShown ? (
                <Button
                  style={{ marginTop: 10 }}
                  key={i.id}
                  onClick={() =>
                    setShowHint((prev) => [
                      ...prev.filter((j) => j.id !== i.id),
                      { ...i, isShown: true },
                    ])
                  }
                >
                  Vis hint {i.id}
                </Button>
              ) : (
                i.isShown && (
                  <p key={i.id}>
                    {i.id}: {i.message}
                  </p>
                )
              )
            )}
        </div>
        <h3>Svar</h3>
        <InputText
          disabled={answeredCorrectly}
          defaultValue={input}
          onChange={(e) => setInput(e.target.value)}
        ></InputText>
        {!answeredCorrectly ? (
          <Button type="button" onClick={() => checkAnswer()}>
            Sjekk svar
          </Button>
        ) : (
          <>
            <Button
              type="button"
              severity="success"
              onClick={() => onCorrectAnswer()}
            >
              Bra jobba! Gå videre
            </Button>
          </>
        )}
      </div>
    </div>
  );
};
