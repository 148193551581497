import { Stack } from "@mui/material";
import React from "react";
import { LoginComponent } from "../Components/Login";
import { HashLoader } from "react-spinners";
import { useAuth } from "../Contexts/AuthContext";

type Props = {
  children?: React.ReactNode;
};

export const LoginPage: React.FC<Props> = ({ children }) => {
  const { user, verifyUser } = useAuth();

  return (
    <>
      {user === undefined ? (
        <Stack
          style={{
            height: "100vh",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <HashLoader
            color={"#123abc"}
            loading={true}
            size={150}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </Stack>
      ) : (
        <>{user !== null ? { children } : <LoginComponent></LoginComponent>}</>
      )}
    </>
  );
};
