import { InputText } from "primereact/inputtext";
import { EasterLogicPuzzle } from "./LogicPuzzle";
import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Tag } from "primereact/tag";
import { useNavigate, useNavigation } from "react-router-dom";

type Category = {
  category: string;
  inputs: string[];
  options: string[];
};

const correctAnswers = [
  ["preben", "romvesen", "5 minutter", "loftstua"],
  ["tamira", "kidnappet", "15 minutter", "terrassen"],
  ["mathias", "shopping", "20 minutter", "parkeringen"],
  ["marte", "synkehull", "10 minutter", "boblebadet"],
];

export const EasterPuzzle: React.FC = () => {
  const [answer, setAnswer] = useState<boolean>(false);
  const correctAnswer = "100";
  const navigate = useNavigate();
  const [inputs, setInputs] = useState<Category[]>([]);
  const [showHint, setShowHint] = useState<
    {
      id: number;
      isShown: boolean;
      minutes: number;
      showButton: boolean;
      message: string;
    }[]
  >([
    {
      id: 5,
      isShown: false,
      minutes: 7,
      showButton: false,
      message: "Personen som foreslo shopping er en gutt",
    },
    {
      id: 6,
      isShown: false,
      minutes: 10,
      showButton: false,
      message: "Den som foreslo å lete på parkeringen ville vente lengst",
    },
    {
      id: 7,
      isShown: false,
      minutes: 13,
      showButton: false,
      message: "Marte foreslo å lete i boblebadet",
    },
    {
      id: 8,
      isShown: false,
      minutes: 15,
      showButton: false,
      message: "Mathias ville vente i 20 minutter",
    },
  ]);

  const saveToLocalStorage = (data: Category[]) => {
    try {
      const serializedData = JSON.stringify(data);
      localStorage.setItem("easterPuzzleData", serializedData);
    } catch (err) {
      console.error("Could not save to local storage.", err);
    }
  };

  // Function to load data from local storage
  const loadFromLocalStorage = () => {
    try {
      const serializedData = localStorage.getItem("easterPuzzleData");
      return serializedData
        ? JSON.parse(serializedData)
        : [
            {
              category: "Person",
              inputs: ["", "", "", ""],
              options: ["Preben", "Tamira", "Mathias", "Marte"],
            },
            {
              category: "Forslag",
              inputs: ["", "", "", ""],
              options: ["Romvesen", "Kidnappet", "Shopping", "Synkehull"],
            },
            {
              category: "Ventetid",
              inputs: ["", "", "", ""],
              options: [
                "5 minutter",
                "10 minutter",
                "15 minutter",
                "20 minutter",
              ],
            },
            {
              category: "Letested",
              inputs: ["", "", "", ""],
              options: ["Parkeringen", "Boblebadet", "Terrassen", "Loftstua"],
            },
          ]; // Default data if nothing is in local storage
    } catch (err) {
      console.error("Could not load from local storage.", err);
      return []; // Return default state in case of an error
    }
  };

  const checkAnswer = () => {
    const answers = inputs.map((cols, index) =>
      inputs.map((cols2, index2) => inputs[index2].inputs[index])
    );

    const allCorrect = answers.map((answer) => {
      const correctAnswerRow = correctAnswers.find(
        (i) => i[0].toLowerCase() === answer[0].toLowerCase()
      );

      const answeredCorrectlyRow = correctAnswerRow?.map(
        (i, index) => i.toLowerCase() === answer[index].toLowerCase()
      );

      return answeredCorrectlyRow?.every((i) => i === true);
    });

    if (inputs.some((i) => i.inputs.some((j) => j === "" || !j))) {
      return alert("Noen svar mangler");
    } else if (allCorrect.every((i) => i === true)) {
      setAnswer(true);
    } else {
      return alert("Feil svar, prøv igjen!");
    }
  };

  // Effect to load data from local storage on mount
  useEffect(() => {
    const loadedData = loadFromLocalStorage();
    setInputs(loadedData);
  }, []); // Empty dependency array means this effect runs once on mount

  // Effect to save data to local storage whenever it changes
  useEffect(() => {
    if (inputs.length > 0) saveToLocalStorage(inputs);
  }, [inputs]);

  const handleInputChange = (
    categoryIndex: number,
    inputIndex: number,
    newValue: string
  ) => {
    setInputs((currentInputs) =>
      currentInputs.map((category, idx) =>
        idx === categoryIndex
          ? {
              ...category,
              inputs: category.inputs.map((input, i) =>
                i === inputIndex ? newValue : input
              ),
            }
          : category
      )
    );
  };

  useEffect(() => {
    const intervals: NodeJS.Timeout[] = [];
    showHint.forEach((hint) => {
      const interval = setTimeout(() => {
        setShowHint((currentHints) =>
          currentHints.map((currentHint) =>
            currentHint.id === hint.id
              ? { ...currentHint, showButton: true }
              : currentHint
          )
        );
      }, hint.minutes * 60000);

      intervals.push(interval);
    });

    return () => {
      intervals.forEach((interval) => clearInterval(interval));
    };
  }, []);

  const columnLabels = [
    "Preben",
    "Tamira",
    "Mathias",
    "Marte",
    "Parkeringen",
    "Boblebadet",
    "Terrassen",
    "Loftstua",
    "5 minutter",
    "10 minutter",
    "15 minutter",
    "20 minutter",
  ];

  const rowLabels = [
    "Romvesen",
    "Kidnappet",
    "Shopping",
    "Synkehull",
    "5 minutter",
    "10 minutter",
    "15 minutter",
    "20 minutter",
    "Parkeringen",
    "Boblebadet",
    "Terrassen",
    "Loftstua",
  ];

  const optionTemplate = (option: string) => {
    return (
      <div className="flex align-items-center">
        <Button>{option}</Button>
      </div>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minWidth: 480,
      }}
    >
      <div style={{ textAlign: "center", maxWidth: 600, padding: 10 }}>
        <h3>Savnede personer</h3>
        <p>
          Hvor er alle sammen? spurte Mathias da de hadde spist. Gjengen så seg
          rundt i hytta og stusset. Fra hintene under, kan du finne ut hvor hver
          person foreslo at de andre kunne være, hvor lenge de burde vente før
          de skulle begynne å lete etter dem og hvor de skulle lete først?
        </p>
        <h4>Hint:</h4>
        <p>
          1. “Det virker åpenbart for meg», sa Preben, «at vi har blitt angrepet
          av romvesener og at resten av familien har blitt tatt med for å bli
          eksperimentert på». Han var ikke den som foreslo å se i boblebadet
          først.
        </p>
        <p>
          2. «Alltid når jeg våkner opp hjemme og finner huset tomt» sa en av
          personene, «så er det fordi mora mi har reist på shopping. Vi burde se
          på parkeringen for å se om alle bilene er der.» Hen var ikke personen
          som foreslo å vente ti minutter før de begynte å lete.
        </p>
        <p>
          3. «Jeg tror vi burde lete på terrassen først,» sa Tamira, «det er en
          sånn plass folk gjerne er.» Tamira, som ikke foreslo å vente lengst
          før de begynte å lete etter de andre, var ikke den som, etter å ha
          sett på nyhetene, var overbevist om at resten av familien hadde ramlet
          ned i et stort synkehull.
        </p>
        <p>
          4. Marte syntes de skulle vente lenger enn den personen som foreslo at
          de skulle begynne å lete ved å sjekke loftstua, men ikke så lenge som
          den personen som hadde konkludert med at hele familien hadde blitt
          kidnappet av en gjeng bankranere eller kanskje smuglere.
        </p>
        <div style={{ display: "flex", flexDirection: "column" }}>
          {showHint
            .sort((a, b) => a.id - b.id)
            .map((i) =>
              i.showButton && !i.isShown ? (
                <Button
                  style={{ marginTop: 10 }}
                  key={i.id}
                  onClick={() =>
                    setShowHint((prev) => [
                      ...prev.filter((j) => j.id !== i.id),
                      { ...i, isShown: true },
                    ])
                  }
                >
                  Vis hint {i.id}
                </Button>
              ) : (
                i.isShown && (
                  <p key={i.id}>
                    {i.id}: {i.message}
                  </p>
                )
              )
            )}
        </div>
      </div>
      <h3>Oppgave</h3>
      <EasterLogicPuzzle
        categories={3}
        gridSize={4}
        rowLabels={rowLabels}
        columnLabels={columnLabels}
      ></EasterLogicPuzzle>
      <div
        style={{ display: "flex", marginTop: 10 }}
        className="easter-logic-puzzle-inputs"
      >
        {inputs.map((category, categoryIndex) => (
          <div
            key={`category-${categoryIndex}`}
            style={{ display: "flex", flexDirection: "column", marginRight: 5 }}
          >
            <div>{category.category}</div>
            {category.inputs.map((input, inputIndex) => (
              <Dropdown
                optionDisabled={(option) =>
                  category.inputs.includes(option) && option !== ""
                }
                key={`input-${categoryIndex}-${inputIndex}`}
                value={input}
                options={[...category.options, ""]}
                onChange={(e) =>
                  handleInputChange(categoryIndex, inputIndex, e.target.value)
                }
              ></Dropdown>
            ))}
          </div>
        ))}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          margin: "20px 0px",
        }}
      >
        {!answer ? (
          <Button type="button" onClick={checkAnswer}>
            Sjekk svar
          </Button>
        ) : (
          <Tag
            severity={"success"}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <p>Riktig svar!</p>
            <Button type="button" onClick={() => navigate("/easter/map1sdflksd")}>Gå videre</Button>
          </Tag>
        )}
      </div>
    </div>
  );
};
