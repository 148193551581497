import React from "react";
import { Route, Routes } from "react-router-dom";
import { LoginPage } from "./Pages/Login";
import { HomePage } from "./Pages/Home";
import { PrivacyPolicyPage } from "./Pages/PrivacyPolicy";
import { TermsOfServicePage } from "./Pages/TermsOfService";
import { useAuth } from "./Contexts/AuthContext";
import { UnauthorizedPage } from "./Pages/Unauthorized";
import { AdminPage } from "./Pages/Admin/Admin";
import { UsersPage } from "./Pages/Admin/Users";
import { AdminHomePage } from "./Pages/Admin/Home";
import { SignupPage } from "./Pages/Signup";
import { SignupInvitePage } from "./Pages/SignupInvite";
import { InvitationsPage } from "./Pages/Admin/Invitations";
import { AdminImageComponent } from "./Pages/Admin/Images";
import { AdminEmailPage } from "./Pages/Admin/Email";
import { EasterHome } from "./Pages/Easter/Home";
import { EasterHotNCold } from "./Pages/Easter/HotNCold";
import { EasterLogicPuzzle } from "./Pages/Easter/LogicPuzzle";
import { EasterPuzzle } from "./Pages/Easter/Puzzle";
import { FontsGame } from "./Pages/Easter/FontsGame";
import { EasterMap } from "./Pages/Easter/maps/EasterMap";
import { EasterMatchesGame } from "./Pages/Easter/MatchesGame";
import { EasterPhoneGame } from "./Pages/Easter/PhoneGame";

type Props = {
  children?: React.ReactNode;
};

export const AppRouter: React.FC<Props> = ({ children }) => {
  const { user, verifyUser } = useAuth();

  return (
    <Routes>
      <Route
        path="/"
        element={!user ? <LoginPage></LoginPage> : <HomePage></HomePage>}
      ></Route>
      <Route
        path="/unauthorized"
        element={<UnauthorizedPage></UnauthorizedPage>}
      ></Route>
      <Route path="/admin" element={<AdminPage></AdminPage>}>
        <Route path="home" element={<AdminHomePage></AdminHomePage>}></Route>
        <Route path="users" element={<UsersPage></UsersPage>}></Route>
        <Route
          path="invitations"
          element={<InvitationsPage></InvitationsPage>}
        ></Route>
        <Route
          path="images"
          element={<AdminImageComponent></AdminImageComponent>}
        ></Route>
        <Route path="email" element={<AdminEmailPage></AdminEmailPage>}></Route>
      </Route>
      <Route path="/signup" element={<SignupPage></SignupPage>}></Route>
      <Route
        path="/signup/:inviteCode"
        element={<SignupInvitePage></SignupInvitePage>}
      ></Route>

      <Route
        path="/privacy_policy"
        element={<PrivacyPolicyPage></PrivacyPolicyPage>}
      ></Route>
      <Route
        path="/terms_of_service"
        element={<TermsOfServicePage></TermsOfServicePage>}
      ></Route>

      <Route path="/easter" element={<EasterHome></EasterHome>}></Route>

      <Route
        path="/easter/puzzle"
        element={<EasterPuzzle></EasterPuzzle>}
      ></Route>
      <Route
        path="/easter/map1sdflksd"
        element={
          <EasterMap
            markers={[
              {
                position: [59.14614910060944, 6.0759490728378305],
                label: "Target",
              },
            ]}
          ></EasterMap>
        }
      ></Route>

      <Route path="/easter/fonts" element={<FontsGame></FontsGame>}></Route>
      <Route
        path="/easter/map2sdjfnsd"
        element={
          <EasterMap
            markers={[
              {
                position: [59.148066592550336, 6.07771396636963],
                label: "Target",
              },
            ]}
          ></EasterMap>
        }
      ></Route>

      <Route
        path="/easter/matches"
        element={<EasterMatchesGame></EasterMatchesGame>}
      ></Route>
      <Route
        path="/easter/map3wejfwnj"
        element={
          <EasterMap
            markers={[
              {
                position: [59.147651190929544, 6.076474785804749],
                label: "Target",
              },
            ]}
          ></EasterMap>
        }
      ></Route>

      <Route
        path="/easter/phone"
        element={<EasterPhoneGame></EasterPhoneGame>}
      ></Route>
      <Route
        path="/easter/map4qoiwej"
        element={
          <EasterMap
            markers={[
              {
                position: [59.147299058455, 6.077767610549927],
                label: "Target",
              },
            ]}
          ></EasterMap>
        }
      ></Route>

      <Route
        path="/easter/map"
        element={
          <EasterMap
            markers={[
              {
                position: [59.14782450480151, 6.077080965042115],
                label: "Target",
              },
            ]}
          ></EasterMap>
        }
      ></Route>

      <Route
        path="/easter/maps"
        element={
          <EasterMap
            markers={[
              {
                position: [59.14782450480151, 6.077080965042115],
                label: "Target",
              },
            ]}
          ></EasterMap>
        }
      ></Route>

      <Route
        path="/easter/sondre/map1"
        element={
          <EasterMap
            markers={[
              {
                position: [59.14614910060944, 6.0759490728378305],
                label: "Target",
              },
            ]}
          ></EasterMap>
        }
      ></Route>

      <Route
        path="/easter/sondre/map2"
        element={
          <EasterMap
            markers={[
              {
                position: [59.148066592550336, 6.07771396636963],
                label: "Target",
              },
            ]}
          ></EasterMap>
        }
      ></Route>

      <Route
        path="/easter/sondre/map3"
        element={
          <EasterMap
            markers={[
              {
                position: [59.147651190929544, 6.076474785804749],
                label: "Target",
              },
            ]}
          ></EasterMap>
        }
      ></Route>

      <Route
        path="/easter/sondre/map4"
        element={
          <EasterMap
            markers={[
              {
                position: [59.147299058455, 6.077767610549927],
                label: "Target",
              },
            ]}
          ></EasterMap>
        }
      ></Route>
    </Routes>
  );
};
