import React from "react";
import { LoginPage } from "./Pages/Login";
import { AppRouter } from "./AppRouter";
import { Footer } from "./Components/Footer";
import { PrimeReactProvider, PrimeReactContext } from "primereact/api";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import { ThemeProvider, createTheme } from "@mui/material";
import "primeicons/primeicons.css";
import { ToastProvider } from "./Contexts/ToastProvider";
import { NotificationService } from "./Services/NotificationService";
import { getMessaging, getToken } from "firebase/messaging";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const theme = createTheme({
  palette: {
    error: { main: "#d32f2f" },
    success: { main: "#689f38" },
    warning: { main: "#fbc02d" },
  },
});

const firebaseConfig = {
  apiKey: "AIzaSyCndYJH4A6vDafEd99wEHD6NmWtDKt7wGw",
  authDomain: "wedding-323b3.firebaseapp.com",
  projectId: "wedding-323b3",
  storageBucket: "wedding-323b3.appspot.com",
  messagingSenderId: "256582350830",
  appId: "1:256582350830:web:b5d066be21d0cf3266c0ff",
  measurementId: "G-HKFQB4XLGP",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {
  return (
    <PrimeReactProvider>
      <ThemeProvider theme={theme}>
        <ToastProvider>
          <NotificationService firebaseApp={app}>
            <div className="App">
              <div style={{ flexGrow: 1 }}>
                <AppRouter></AppRouter>
              </div>
              {/* <Footer></Footer> */}
            </div>
          </NotificationService>
        </ToastProvider>
      </ThemeProvider>
    </PrimeReactProvider>
  );
}

export default App;
