import { Chart } from "primereact/chart";
import React, { useEffect, useState } from "react";

type Props = {
  labels: string[];
  data: number[];
  type?: "doughnut" | "pie";
  label?: string;
};

export const RoundChart: React.FC<Props> = ({ labels, data, type, label }) => {
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    if (!data || !labels) return;
    const documentStyle = getComputedStyle(document.documentElement);
    const newChartData = {
      labels: labels,
      datasets: [
        {
          data: data,
          backgroundColor: [
            documentStyle.getPropertyValue("--blue-500"),
            documentStyle.getPropertyValue("--yellow-500"),
            documentStyle.getPropertyValue("--green-500"),
          ],
          hoverBackgroundColor: [
            documentStyle.getPropertyValue("--blue-400"),
            documentStyle.getPropertyValue("--yellow-400"),
            documentStyle.getPropertyValue("--green-400"),
          ],
        },
      ],
    };
    const options = {
      cutout: "60%",
    };
    setChartData(newChartData);
    setChartOptions(options);
  }, [data, labels]);

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      {label && <p style={{ marginTop: 0 }}>{label}</p>}
      <Chart
        // style={{ height: "300px" }}
        type={type || "doughnut"}
        data={chartData}
        options={chartOptions}
        className="w-full md:w-30rem"
      />
    </div>
  );
};
