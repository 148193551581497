import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const FontsGame: React.FC = () => {
  const [input, setInput] = useState<string>("");
  const correctAnswer = "påskekos på nordskår";
  const [answeredCorrectly, setAnsweredCorrectly] = useState<boolean>(false);
  const navigate = useNavigate();

  const checkAnswer = () => {
    if (input.toLowerCase() === correctAnswer.toLowerCase()) {
      setAnsweredCorrectly(true);
    } else {
      alert("Feil svar, prøv igjen :)");
    }
  };

  const onCorrectAnswer = () => {
    navigate("/easter/map2sdjfnsd");
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <DataTable
        value={[
          {
            name: "mathias",
          },
          {
            name: "marte",
          },
          {
            name: "preben",
          },
          {
            name: "tamira",
          },
          {
            name: "theodor",
          },
          {
            name: "giske",
          },
          {
            name: "odd magne",
          },
          {
            name: "ånund",
          },
          {
            name: "sverre",
          },
        ]}
        tableStyle={{}}
      >
        <Column field="name"></Column>
        <Column field="name" className="font-code"></Column>
      </DataTable>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 5,
          textAlign: "center",
        }}
      >
        <h3>Hva betyr koden?</h3>
        <p className="font-code">{correctAnswer}</p>
        <InputText
          disabled={answeredCorrectly}
          defaultValue={input}
          onChange={(e) => setInput(e.target.value)}
        ></InputText>
        {!answeredCorrectly ? (
          <Button type="button" onClick={() => checkAnswer()}>
            Sjekk svar
          </Button>
        ) : (
          <>
            <Button
              type="button"
              severity="success"
              onClick={() => onCorrectAnswer()}
            >
              Bra jobba! Gå videre
            </Button>
          </>
        )}
      </div>
    </div>
  );
};
