import React, { useContext, useEffect, useRef } from "react";
import axiosInstance from "../Axios";
import { FirebaseApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { onBackgroundMessage } from "firebase/messaging/sw";
import ToastContext from "../Contexts/ToastContext";

type Props = {
  children?: React.ReactNode;
  firebaseApp: FirebaseApp;
};

export const NotificationService: React.FC<Props> = ({
  children,
  firebaseApp,
}) => {
  const { showToast } = useContext(ToastContext);
  const hasRegistered = useRef(false);

  useEffect(() => {
    if (window.location.pathname.includes("easter")) return;
    if (!firebaseApp || (!window.location.host.includes('localhost') && window.location.protocol.toLowerCase() !== 'https')) return;
    requestNotificationPermissionAndToken();
    listenForMessages();
  }, [firebaseApp]);

  const requestNotificationPermissionAndToken = () => {
    if ("Notification" in window) {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          if (hasRegistered.current) return;
          hasRegistered.current = true;
          const messaging = getMessaging(firebaseApp);
          getToken(messaging, {
            vapidKey:
              "BJSGTEqhmMn0gDKTuV064VUHEYP8A_xr9khPMjjKs9_tvrspg-EqQchXwBy63VC4-ZKBkcWnpqzS7HMnIkN3szg",
          })
            .then((currentToken) => {
              if (currentToken) {
                sendTokenToBackend(currentToken);
              } else {
                console.log(
                  "No registration token available. Request permission to generate one."
                );
              }
            })
            .catch((err) => {
              console.error("An error occurred while retrieving token. ", err);
            });
        } else {
          console.log("Notification permission denied.");
        }
      });
    }
  };

  const listenForMessages = () => {
    const messaging = getMessaging(firebaseApp);
    onMessage(messaging, (payload) => {
      console.log("Message received. ", payload);
      showToast({
        severity: "success",
        summary: payload?.notification?.title,
        detail: payload?.notification?.body,
      });
    });
  };

  const sendTokenToBackend = async (token: string) => {
    const subscriptionData = {
      token: token,
      enabled: true,
    };

    const res = await axiosInstance.post(
      "/account/register-push",
      subscriptionData
    );
  };
  return <>{children}</>;
};
