import { Button, Stack } from "@mui/material";
import { Link, Navigate } from "react-router-dom";

export const PrivacyPolicyPage: React.FC = () => {
  return (
    <div>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        spacing={"evenly"}
      >
        <Link to={"/"}>
          <Button>Back</Button>
        </Link>
        <h1>Privacy Policy</h1>
        <p></p>
      </Stack>
      <div style={{ margin: "0px 30px" }}>
        <h3>1. What Information Do We Collect?</h3>
        <p>
          In order to provide our Services, we collect the following types of
          information:
          <li>
            <b>Personal Information You Disclose to Us:</b> We collect personal
            information that you voluntarily provide to us when registering at
            the Services expressing an interest in obtaining information about
            us or our products and services. The personal information that we
            collect depends on the context of your interactions with us and the
            Services, the choices you make, and the products and features you
            use. The personal information we collect can include the following:
            <li>Name</li>
            <li>Email address</li>
          </li>{" "}
           Information Automatically Collected: We
          automatically collect certain information when you visit, use, or
          navigate the Services. This information does not reveal your specific
          identity (like your name or contact information) but may include
          device and usage information, such as your IP address, browser, and
          device characteristics, operating system, language preferences,
          referring URLs, device name, country, location, information about how
          and when you use our Services, and other technical information.
        </p>
        <h3>2. How Do We Use Your Information?</h3>
        <p>
          We use the information we collect or receive: To facilitate account
          creation and logon process. If you choose to link your account with us
          to a third-party account (such as your Google or Facebook account), we
          use the information you allowed us to collect from those third parties
          to facilitate account creation and logon process. To send
          administrative information to you for related to your account, our
          business purposes, and/or for legal reasons. To enforce our terms,
          conditions, and policies for business purposes, to comply with legal
          and regulatory requirements, or in connection with our contract.
        </p>
        <h3>3. Will Your Information Be Shared with Anyone?</h3>
        <p>
          We only share information with your consent, to comply with laws, to
          provide you with services, to protect your rights, or to fulfill
          business obligations.
        </p>
        <h3>4. Who Will Your Information Be Shared With?</h3>
        <p>
          We only share and disclose your information with the following third
          parties: Third-party login providers (e.g., Google, Facebook) solely
          for authentication purposes.
        </p>
        <h3>5. How Do We Handle Your Social Logins?</h3>
        <p>
          If you choose to register or log in to our services using a social
          media account, we may have access to certain information about you.
          Our Services offer you the ability to register and login using your
          third-party social media account details (like your Google or Facebook
          logins). Where you choose to do this, we will receive certain profile
          information about you from your social media provider. The profile
          information we receive may vary depending on the social media provider
          concerned but will often include your name and email address.
        </p>
        <h3>6. How Long Do We Keep Your Information?</h3>
        <p>
          We keep your information for as long as necessary to fulfill the
          purposes outlined in this privacy policy unless otherwise required by
          law.
        </p>
        <h3>7. How Do We Keep Your Information Safe?</h3>
        <p>
          We have implemented appropriate technical and organizational security
          measures designed to protect the security of any personal information
          we process. However, despite our safeguards and efforts to secure your
          information, no electronic transmission over the Internet or
          information storage technology can be guaranteed to be 100% secure, so
          we cannot promise or guarantee that hackers, cybercriminals, or other
          unauthorized third parties will not be able to defeat our security and
          improperly collect, access, steal, or modify your information.
        </p>
        <h3>8. Do We Collect Information from Minors?</h3>
        <p>
          We do not knowingly collect data from or market to children under 18
          years of age.
        </p>
        <h3>9. What Are Your Privacy Rights?</h3>
        <p>
          You have certain rights under applicable data protection laws. These
          may include the right to request access and obtain a copy of your
          personal information, request rectification or erasure; restrict the
          processing of your personal information; and if applicable, data
          portability.
        </p>
        <h3>10. Controls for Do-Not-Track Features</h3>
        <p>
          Most web browsers and some mobile operating systems and mobile
          applications include a Do-Not-Track (“DNT”) feature or setting you can
          activate to signal your privacy preference not to have data about your
          online browsing activities monitored and collected.
        </p>
        <h3>11. Updates to This Policy</h3>
        <p>
          We may update this privacy policy from time to time. The updated
          version will be indicated by an updated “Revised” date and the updated
          version will be effective as soon as it is accessible.
        </p>
      </div>
    </div>
  );
};
