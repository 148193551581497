import { useNavigate } from "react-router-dom";
import { useAuth } from "../Contexts/AuthContext";
import { Stack } from "@mui/material";
import { InputText } from "primereact/inputtext";
import InfoIcon from "@mui/icons-material/Info";
import { InputSwitch } from "primereact/inputswitch";
import { useEffect, useRef, useState } from "react";
import { IUser } from "../Types/AuthTypes";
import { Button } from "primereact/button";
import { OverlayPanel } from "primereact/overlaypanel";
import axiosInstance from "../Axios";

export const SignupPage: React.FC = () => {
  const { user, setUser } = useAuth();
  const navigate = useNavigate();
  const [userState, setUserState] = useState<Partial<IUser> | null>(null);
  const opEmail = useRef<OverlayPanel>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (user) {
      setUserState({ ...user, allowNotifications: true });
    }
  }, [user]);

  if (user === undefined) return <div>Loading...</div>;
  else if (user === null) navigate("/unauthorized");

  const updateAccountInfo = async () => {
    if (userState) {
      try {
        const updatedUser = await axiosInstance.put("/account/me", userState);
        setUser(updatedUser.data);
        // if (updatedUser) {
        //   setUserState(updatedUser.data);
        // }
        navigate("/");
      } catch (err) {
        console.error(err);
        setError(
          "Det skjedde en feil ved oppdatering av brukeren din. Prøv igjen, hvis feilen vedvarer, gå videre og prøv igjen en annen gang, eventuelt kontakt Sondre"
        );
      }
    }
  };

  return (
    <>
      <div
        style={{
          height: "100%",
          backgroundImage: "url('/images/login_background.jpg')",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Stack style={{ height: "40vh" }}>
          <img
            src="/images/wedding_logo_512x512.png"
            height={"100%"}
            style={{ width: "auto", objectFit: "scale-down" }}
          ></img>
        </Stack>
        <Stack flexDirection={"row-reverse"} justifyContent={"space-between"}>
          <div
            style={{
              padding: 20,
              backgroundColor: "#eed9c480",
              borderRadius: 30,
              marginRight: 40,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <h3 style={{ margin: 5, marginTop: 0 }}>Epost</h3>
              <InfoIcon
                fontSize="small"
                onMouseEnter={(e) =>
                  opEmail?.current?.toggle && opEmail.current.toggle(e)
                }
                onMouseLeave={(e) =>
                  opEmail?.current?.toggle && opEmail.current.toggle(e)
                }
                onClick={(e) =>
                  opEmail?.current?.toggle && opEmail.current.toggle(e)
                }
              ></InfoIcon>
              <OverlayPanel ref={opEmail}>
                Eposten du kan nås på. Vi vil bare sende epost hvis det er
                viktig informasjon som må formidles
              </OverlayPanel>
            </div>
            <InputText
              keyfilter="email"
              value={userState?.email || ""}
              onChange={(e) =>
                setUserState((prev) => ({ ...prev, email: e.target.value }))
              }
            ></InputText>
            <div
              style={{ display: "flex", alignItems: "center", marginTop: 10 }}
            >
              <h3 style={{ margin: 5 }}>Motta varsler</h3>
              <InfoIcon
                fontSize="small"
                onMouseEnter={(e) =>
                  opEmail?.current?.toggle && opEmail.current.toggle(e)
                }
                onMouseLeave={(e) =>
                  opEmail?.current?.toggle && opEmail.current.toggle(e)
                }
                onClick={(e) =>
                  opEmail?.current?.toggle && opEmail.current.toggle(e)
                }
              ></InfoIcon>
              <OverlayPanel ref={opEmail}>
                Eposten du kan nås på. Vi vil bare sende epost hvis det er
                viktig informasjon som må formidles
              </OverlayPanel>
            </div>
            <InputSwitch
              checked={userState?.allowNotifications as boolean}
              onChange={(e) =>
                setUserState((prev) => ({
                  ...prev,
                  allowNotifications: e.value,
                }))
              }
            ></InputSwitch>
            <Button style={{ marginTop: 15 }} onClick={updateAccountInfo}>
              Opprett
            </Button>
          </div>
        </Stack>
      </div>
    </>
  );
};
