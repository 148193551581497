import React, { useEffect, useState } from "react";
import moment from "moment-timezone";

interface TimeLeft {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

export const Countdown: React.FC = () => {
  const [weddingDateAsDate, setWeddingDateAsDate] = useState<Date>(new Date());
  const [timeLeft, setTimeLeft] = useState<TimeLeft>({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const weddingDateAsString = weddingDateAsDate.toLocaleDateString("nb-NO", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  useEffect(() => {
    const weddingDateUTC = new Date("2025-05-24T13:30:00.000Z");
    const weddingDateNorwegian = moment(weddingDateUTC).tz("Europe/Oslo");
    setWeddingDateAsDate(weddingDateNorwegian.toDate());
  }, []);

  const calculateTimeLeft = (): TimeLeft => {
    const difference = +weddingDateAsDate - +new Date();
    let timeLeft: TimeLeft = { days: 0, hours: 0, minutes: 0, seconds: 0 };

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  useEffect(() => {
    const id = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => {
      clearInterval(id);
    };
  }, [weddingDateAsDate]); // Include targetDate in the dependency array to recalculate when it changes

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        fontFamily: "Julius Sans One",
      }}
    >
      <h1 style={{ fontSize: 60, fontWeight: 400 }}>
        {weddingDateAsString.charAt(0).toUpperCase() +
          weddingDateAsString.slice(1)}
      </h1>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#CFB09566",
          padding: 30,
          borderRadius: 20,
          width: "100%",
          alignItems: "center",
          gap: 20,
        }}
      >
        <div
          style={{
            display: "flex",
            fontSize: 30,
            fontWeight: 400,
            justifyContent: "space-between",
            width: "100%",
          }}
          className={"flex-column-mobile"}
        >
          <span>{timeLeft.days} DAGER</span>
          <span>{timeLeft.hours} TIMER</span>
          <span>{timeLeft.minutes} MINUTTER</span>
          <span>{timeLeft.seconds} SEKUNDER</span>
        </div>
        <h2
          style={{
            fontFamily: "Italiana",
            fontWeight: 400,
            margin: 0,
            textAlign: "center",
          }}
          className="countdown-subtitle-2-mobile"
        >
          til vi gir hverandre vårt ja!
        </h2>
      </div>
    </div>
  );
};
