// context/AuthContext.tsx
import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useCallback,
} from "react";
import { IUser, IAuthContext } from "../Types/AuthTypes";
import Axios from "../Axios";

const AuthContext = createContext<IAuthContext | undefined>(undefined);

type Props = {
  children: React.ReactNode;
};

export const AuthProvider: React.FC<Props> = ({ children }) => {
  const [user, setUser] = useState<IUser | null | undefined>(undefined);

  const verifyUser = useCallback(async () => {
    if (window.location.pathname.includes("easter")) return;
    console.log(window.location.pathname)
    try {
      const response = await Axios.get<IUser>("/account");
      setUser(response.data);
    } catch (error) {
      console.error("Failed to verify user", error);
      setUser(null);
    }
  }, []);

  useEffect(() => {
    verifyUser();
  }, [verifyUser]);

  return (
    <AuthContext.Provider value={{ user, setUser, verifyUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
