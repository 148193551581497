import { ToastMessage } from "primereact/toast";
import React, { createContext } from "react";

interface ToastContextProps {
  showToast: ({ severity, summary, detail, life }: ToastMessage) => void;
}

// Provide initial noop function for context default value
const ToastContext = createContext<ToastContextProps>({
  showToast: () => console.error("No ToastProvider found"),
});

export default ToastContext;
