import { Button, Stack } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

export const TermsOfServicePage = () => {
  return (
    <div className="terms-container">
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        spacing={"evenly"}
      >
        <Link to={"/"}>
          <Button>Back</Button>
        </Link>
        <h1>Terms of Service</h1>
        <p></p>
      </Stack>
      <div style={{ margin: "0px 30px" }}>
        <p>Last Updated: 21.02.2024</p>

        <h3>1. Introduction</h3>
        <p>
          Welcome to Thaule Blegen Wedding website, a private platform for
          friends and family. This website is operated by Sondre Blegen. Sondre
          Blegen offers this website, including all information, tools, and
          services available from this site to you, the user, conditioned upon
          your acceptance of all terms, conditions, policies, and notices stated
          here.
        </p>

        <h3>2. Privacy and Security</h3>
        <p>
          Your privacy and data security are our top priorities. All data is
          securely stored on Azure cloud services and every communication with
          our platform is encrypted using HTTPS protocol. We are committed to
          ensuring that your information is secure and is only accessible to
          authorized individuals.
        </p>

        <h3>3. Access and Use</h3>
        <p>
          Access to Thaule Blegen Wedding website is strictly limited to friends
          and family members who have been invited. Unauthorized access or use
          of the platform is prohibited and may result in removal or legal
          action.
        </p>

        {/* Continue adding other sections relevant to your terms of use */}

        <h3>4. Data Usage</h3>
        <p>
          The information and data on Thaule Blegen Wedding website are for
          personal use only and should not be distributed or used for commercial
          purposes. Respect the privacy and rights of others using the platform.
        </p>

        {/* Add more sections as needed */}

        <h3>5. Changes to Terms</h3>
        <p>
          We reserve the right to modify or replace these Terms at any time.
          What constitutes a material change will be determined at our sole
          discretion. We encourage users to frequently check this page for any
          changes.
        </p>

        {/* Continue adding other sections relevant to your terms of use */}
      </div>
    </div>
  );
};
