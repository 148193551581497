import { useContext, useEffect, useRef, useState } from "react";
import { EmailBody } from "../../Types/Email";
import { IUser } from "../../Types/AuthTypes";
import axiosInstance from "../../Axios";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { ToggleButton } from "primereact/togglebutton";
import { SelectButton } from "primereact/selectbutton";
import { Editor } from "primereact/editor";
import { Button } from "primereact/button";
import { PickList, PickListChangeEvent } from "primereact/picklist";
import { Avatar } from "primereact/avatar";
import { Tag } from "primereact/tag";
import { Menubar } from "primereact/menubar";
import AddIcon from "@mui/icons-material/Add";
import { renderToString } from "react-dom/server";
import { OverlayPanel } from "primereact/overlaypanel";
import { useAuth } from "../../Contexts/AuthContext";
import ToastContext from "../../Contexts/ToastContext";

export const AdminEmailPage: React.FC = () => {
  const [users, setUsers] = useState<IUser[]>([]);
  const [emailBody, setEmailBody] = useState<EmailBody>({
    recipients: [],
    subject: "",
    content: "",
    asHTML: true,
  });
  const [selectedUsers, setSelectedUsers] = useState<IUser[]>([]);
  const op = useRef<any>(null);
  const { user } = useAuth();
  const { showToast } = useContext(ToastContext);

  useEffect(() => {
    const getUsers = async () => {
      const response = await axiosInstance.get<IUser[]>("/admin/users");
      setUsers(response?.data);
    };
    getUsers();
  }, []);

  useEffect(() => {
    setEmailBody((prev) => ({ ...prev, recipients: selectedUsers }));
  }, [selectedUsers]);

  const onChange = (event: PickListChangeEvent) => {
    setUsers(event.source);
    setSelectedUsers(event.target);
  };

  const sendPushNotification = async () => {
    try {
      const body = {
        subject: emailBody.subject,
        content: emailBody.content,
        recipients: selectedUsers,
      };
      await axiosInstance.post("/admin/email/pushNotification", body);
      showToast({
        severity: "success",
        summary: "Push varsel sendt",
        detail: `Push varsel ble sendt til ${selectedUsers.length} mottakere.`,
      });
    } catch (error: any) {
      console.error(error);
      showToast({
        severity: "error",
        summary: "Error",
        detail: error?.response?.data?.message,
      });
    }
  };

  const sendEmail = async () => {
    try {
      const body: EmailBody = {
        asHTML: emailBody.asHTML,
        content: emailBody.asHTML
          ? renderToString(
              <EmailBodyRender emailBody={emailBody}></EmailBodyRender>
            )
          : emailBody.content,
        recipients: selectedUsers,
        subject: emailBody.subject,
      };
      await axiosInstance.post("/admin/email", body);
      showToast({
        severity: "success",
        summary: "Epost sendt",
        detail: `Eposten ble sendt til ${selectedUsers.length} mottakere.`,
      });
    } catch (error: any) {
      console.error(error);
      showToast({
        severity: "error",
        summary: "Error",
        detail: error?.response?.data?.message,
      });
    }
  };

  const itemTemplate = (user: IUser) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex" }}>
          <Avatar
            label={user?.name
              ?.split(" ")
              ?.map((i) => i[0])
              ?.join("")
              ?.toUpperCase()}
            size="large"
            shape="circle"
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              marginLeft: 5,
            }}
          >
            <p style={{ margin: 0 }}>{user.name}</p>
            <p style={{ margin: 0 }}>{user.email}</p>
          </div>
        </div>
        {user.isComing === true ? (
          <Tag severity="success" value="Kommer" />
        ) : (
          <>
            {user.isComing === false ? (
              <Tag severity="danger" value="Kommer ikke" />
            ) : (
              <Tag severity="warning" value="Ikke svart" />
            )}
          </>
        )}
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <>
        <span className="ql-formats">
          <select className="ql-font"></select>
          <select className="ql-size"></select>
        </span>
        <span className="ql-formats">
          <button className="ql-bold" aria-label="Bold"></button>
          <button className="ql-italic"></button>
          <button className="ql-underline"></button>
          <button className="ql-strike"></button>
        </span>
        <span className="ql-formats">
          <select className="ql-color"></select>
          <select className="ql-background"></select>
        </span>
        <span className="ql-formats">
          <button className="ql-header" value="1"></button>
          <button className="ql-header" value="2"></button>
          <button className="ql-blockquote"></button>
          <button className="ql-code-block"></button>
        </span>
        <span className="ql-formats">
          <button className="ql-list" value="ordered"></button>
          <button className="ql-list" value="bullet"></button>
          <button className="ql-indent" value="-1"></button>
          <button className="ql-indent" value="+1"></button>
        </span>
        <span className="ql-formats">
          <select className="ql-align"></select>
        </span>
        <span className="ql-formats">
          <button className="ql-link"></button>
          <button className="ql-image"></button>
          <button className="ql-video"></button>
        </span>
        <span className="ql-formats">
          <button className="ql-clean"></button>
        </span>
        <span className="ql-formats">
          <Button
            type="button"
            onClick={(e) => op?.current && op.current?.toggle(e)}
            style={{ width: "fit-content", color: "black" }}
          >
            Preview
          </Button>
          <OverlayPanel ref={op}>
            {emailBody.asHTML && (
              <div
                style={{ maxWidth: "100%", width: "100%" }}
                dangerouslySetInnerHTML={{
                  __html:
                    `<div>${renderToString(
                      <EmailBodyRender
                        emailBody={emailBody}
                        user={user}
                      ></EmailBodyRender>
                    )}</div>` || "",
                }}
              ></div>
            )}
          </OverlayPanel>
        </span>
      </>
    );
  };
  const header = renderHeader();

  const editorRef = useRef<any>(null);

  const [isQuillReady, setIsQuillReady] = useState(false);

  const checkQuillReadiness = () => {
    const quill = editorRef.current?.getQuill();
    if (quill && quill.getModule("toolbar")) {
      setIsQuillReady(true);
    } else {
      setTimeout(checkQuillReadiness, 100);
    }
  };

  useEffect(() => {
    checkQuillReadiness();
  }, []);

  const imageHandler = () => {
    if (!editorRef.current) return;
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file = input.files && input?.files[0];
      if (!editorRef.current?.getQuill) return;
      if (file) {
        const uploadedImageUrl = await uploadImage(file);

        // Insert the image in the editor
        const range = editorRef.current.getQuill().getSelection(true);
        editorRef.current
          .getQuill()
          .insertEmbed(range.index, "image", uploadedImageUrl, "user");
      }
    };
  };

  const uploadImage = async (file: File): Promise<string> => {
    const formData = new FormData();
    formData.append("image", file);
    try {
      const response = await axiosInstance.post<{ url: string }>(
        "/admin/email/image",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data?.url;
    } catch (error) {
      console.error("Error uploading image:", error);
      // Handle the error appropriately
      throw error; // or return a default value, depending on your use case
    }
  };

  useEffect(() => {
    if (isQuillReady) {
      const quill = editorRef.current.getQuill();
      const toolbar = quill.getModule("toolbar");
      toolbar.addHandler("image", imageHandler);
    }
  }, [isQuillReady]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        padding: 10,
        paddingTop: 30,
        justifyContent: "center",
        gap: 20,
        width: "100vw",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "50%",
        }}
      >
        <span className="p-float-label" style={{ width: "100%" }}>
          <InputText
            id="subject"
            value={emailBody.subject}
            placeholder="Emne"
            style={{ width: "100%" }}
            onChange={(e) =>
              setEmailBody((prev) => ({ ...prev, subject: e.target.value }))
            }
          />
          <label htmlFor="subject">Emne</label>
        </span>

        <Editor
          value={emailBody?.content}
          ref={editorRef}
          onTextChange={(e) =>
            setEmailBody((prev) => ({
              ...prev,
              content: e.textValue,
              contentAsHtml: e.htmlValue || "",
            }))
          }
          style={{ height: "320px" }}
          headerTemplate={header}
        />
      </div>
      <div
        style={{
          width: "50%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ display: "flex" }}>
          {window.location.href.includes("localhost") && (
            <Button
              onClick={() =>
                console.log(
                  renderToString(
                    <EmailBodyRender
                      emailBody={emailBody}
                      user={user}
                    ></EmailBodyRender>
                  )
                )
              }
            >
              Log
            </Button>
          )}

          <Button
            onClick={() => sendEmail()}
            disabled={
              !emailBody?.subject ||
              emailBody.subject == "" ||
              selectedUsers.length < 1
            }
          >
            Send epost
          </Button>
          <Button
            onClick={() => sendPushNotification()}
            disabled={
              !emailBody?.subject ||
              emailBody.subject == "" ||
              selectedUsers.length < 1
            }
          >
            Send push varsel
          </Button>
        </div>
        <Menubar
          style={{
            width: "fit-content",
            justifyContent: "space-evenly",
            marginTop: 20,
          }}
          model={[
            {
              label: "Alle som ikke har svart",
              icon: <AddIcon color="warning" />,
              command: () => {
                const isComing = users.filter(
                  (u) => u.isComing === null || u.isComing === undefined
                );

                setUsers((prev) => prev.filter((i) => !isComing.includes(i)));
                setSelectedUsers((prev) => prev.concat(isComing));
              },
            },
            {
              label: "Alle som ikke kommer",
              icon: <AddIcon color="error" />,
              command: () => {
                const isComing = users.filter((u) => u.isComing === false);

                setUsers((prev) => prev.filter((i) => !isComing.includes(i)));
                setSelectedUsers((prev) => prev.concat(isComing));
              },
            },
            {
              label: "Alle som kommer",
              icon: <AddIcon color="success" />,
              command: () => {
                const isComing = users.filter((u) => u.isComing === true);

                setUsers((prev) => prev.filter((i) => !isComing.includes(i)));
                setSelectedUsers((prev) => prev.concat(isComing));
              },
            },
          ]}
        />

        <PickList
          dataKey="email"
          style={{ width: "100%", marginTop: 10 }}
          source={users}
          target={selectedUsers}
          onChange={onChange}
          itemTemplate={itemTemplate}
          breakpoint="1280px"
          sourceHeader="Epost vil ikke bli sendt"
          targetHeader="Epost vil bli sendt"
          sourceStyle={{ height: "24rem" }}
          targetStyle={{ height: "24rem" }}
          showSourceFilter
          showTargetFilter
        />
      </div>
    </div>
  );
};

const EmailBodyRender: React.FC<{
  emailBody: EmailBody;
  user?: IUser | null;
}> = ({ emailBody, user }) => {
  return (
    <body>
      <style>
        {`
    .ql-syntax {
      background-color: #f5f5f5;
      color: #333;
      overflow: auto;
      padding: 10px;
    }
  `}
      </style>
      <div>
        <div
          dangerouslySetInnerHTML={{
            __html: applyStyles(emailBody.contentAsHtml, user) || "",
          }}
        ></div>
      </div>
    </body>
  );
};

const applyStyles = (html?: string, currentUser?: IUser | null) => {
  if (!html) return "";
  let styledHTML = html;

  styledHTML = styledHTML.replace(/class="ql-indent-([0-9])"/g, (match, p1) => {
    const indentLevel = parseInt(p1, 10);
    const indentSize = 40;
    return `style="margin-left: ${indentLevel * indentSize}px;"`;
  });

  styledHTML = styledHTML.replace(
    /<blockquote>([\s\S]*?)<\/blockquote>/g,
    (match, p1) => {
      return `<div style="border-left: 4px solid #ccc; margin-bottom: 5px; margin-top: 5px; padding-left: 16px;">${p1}</div>`;
    }
  );

  if (currentUser) {
    styledHTML = styledHTML.replace(/{{user.email}}/g, currentUser.email);
    styledHTML = styledHTML.replace(/{{user.name}}/g, currentUser.name);
    styledHTML = styledHTML.replace(
      /{{user.invitelink}}/g,
      `${window.location.origin}/signup/${currentUser?.invitation?.invitationString}` ||
        ""
    );
    styledHTML = styledHTML.replace(/{{url}}/g, `${window.location.origin}`);
  }

  return styledHTML;
};
