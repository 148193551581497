import React, { useEffect, useState } from "react";
import { LoginProvider } from "../Models/LoginProvider";
import axiosInstance from "../Axios";
import { Button, Stack } from "@mui/material";
import { Link } from "react-router-dom";

export const LoginComponent: React.FC = () => {
  const [loginProviders, setLoginProviders] = useState<LoginProvider[]>([]);

  useEffect(() => {
    const fetchLoginProviders = async () => {
      try {
        const response = await axiosInstance.get<LoginProvider[]>(
          "/login/providers"
        );
        setLoginProviders(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchLoginProviders();
  }, []);

  return (
    <>
      <div
        style={{
          height: "100%",
          backgroundImage: "url('/images/login_background.jpg')",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Stack style={{ height: "40vh" }}>
          <img
            src="/images/wedding_logo_512x512.png"
            height={"100%"}
            style={{ width: "auto", objectFit: "scale-down" }}
          ></img>
        </Stack>
        <Stack flexDirection={"row-reverse"} justifyContent={"space-between"}>
          <div
            style={{
              padding: 20,
              backgroundColor: "#eed9c480",
              borderRadius: 30,
              marginRight: 40,
            }}
          >
            <h2
              style={{
                fontSize: 40,
                fontWeight: 400,
                margin: 0,
                textAlign: "center",
              }}
            >
              Logg inn
            </h2>
            <Stack direction={"row"} style={{ marginTop: 20, opacity: 100 }}>
              {loginProviders.map((provider) => (
                <Link
                  to={`${process.env.REACT_APP_API_BASE_URL}/login/${provider.name}`}
                >
                  <img
                    src={`/images/${provider.name?.toLowerCase()}_logo.png`}
                    height={80}
                    style={{ marginRight: 20 }}
                  ></img>
                </Link>
              ))}
            </Stack>
          </div>
        </Stack>
      </div>
    </>
  );
};
