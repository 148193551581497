import { Timeline } from "primereact/timeline";

type WeddingEvent = {
  time: string;
  name: string | React.ReactNode;
};

export const WeddingTimeline: React.FC = () => {
  const events: WeddingEvent[] = [
    {
      time: "13:30",
      name: "VIELSE",
    },
    {
      time: "15:00",
      name: "VELKOMST",
    },
    {
      time: "15:30",
      name: "BILDER",
    },
    {
      time: "16:35",
      name: (
        <>
          BRUDEPARET
          <br /> ANKOMMER
        </>
      ),
    },
    {
      time: "17:00",
      name: "MIDDAG",
    },
    {
      time: "19:30",
      name: "FESTEN",
    },
    {
      time: "23:30",
      name: "NATTMAT",
    },
    {
      time: "02:00",
      name: "VEL HJEM",
    },
  ];

  const customizedContent = (item: WeddingEvent, index: number) => {
    return (
      <div>
        <p style={{ marginBottom: 0, fontWeight: 800 }}>{item.time}</p>
        <p
          style={{
            margin: 0,
          }}
        >
          {item.name}
        </p>
      </div>
    );
  };

  const customizedMarker = (item: WeddingEvent, index: number) => {
    if (item && index % 2 === 0) {
      return (
        <>
          <div className="p-timeline-event-connector"></div>
          <div
            style={{
              height: 2,
              width: 40,
              backgroundColor: "#dee2e6",
              marginLeft: 39,
            }}
          ></div>
        </>
      );
    } else {
      return (
        <>
          <div
            className="p-timeline-event-connector"
            style={{ height: index === events.length - 1 ? 34 : undefined }}
          ></div>
          <div
            style={{
              height: 2,
              width: 40,
              backgroundColor: "#dee2e6",
              marginRight: 38,
            }}
          ></div>
          {index === events.length - 1 && (
            <div
              className="p-timeline-event-connector"
              style={{ height: 34 }}
            ></div>
          )}
        </>
      );
    }
  };

  return (
    <>
      <h1 style={{ textAlign: "center", fontSize: 60, fontWeight: 400 }}>
        DAGEN VÅR
      </h1>
      <Timeline
        value={events}
        align="alternate"
        marker={customizedMarker}
        content={customizedContent}
      />
    </>
  );
};
