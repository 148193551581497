import { Button } from "primereact/button";
import axiosInstance from "../Axios";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { useContext, useState } from "react";
import { IUser } from "../Types/AuthTypes";
import ToastContext from "../Contexts/ToastContext";
import { Checkbox } from "primereact/checkbox";
import Allergies from "./Allergies";
import SaveIcon from "@mui/icons-material/Save";
import AddIcon from "@mui/icons-material/Add";
import { AxiosError, AxiosResponse } from "axios";
import { Invitation } from "../Types/Invitation";

type Props = {
  user?: IUser;
  onUserChanged?: (user: IUser) => void;
};

export const CreateOrUpdateUserDialog: React.FC<Props> = ({
  user,
  onUserChanged,
}) => {
  const [open, setOpen] = useState(false);
  const [newUser, setNewUser] = useState<IUser>(
    user || {
      name: "",
      isAdmin: false,
      email: "",
      allowNotifications: true,
    }
  );
  const { showToast } = useContext(ToastContext);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCreateUser = async () => {
    try {
      let res: AxiosResponse<IUser>;
      let invitation: AxiosResponse<Invitation>;
      if (newUser.rowKey) {
        res = await axiosInstance.put(
          `/admin/users/${newUser.rowKey}`,
          newUser
        );
      } else {
        res = await axiosInstance.post("/admin/users", newUser);

        invitation = await axiosInstance.post("/admin/invitations", {
          intendedForUser: res.data.rowKey,
        });
      }
      onUserChanged && onUserChanged(res.data);
      setOpen(false);
    } catch (err: any) {
      console.error(err);
      showToast({
        severity: "error",
        summary: "Error",
        detail: err?.response?.data?.message,
      });
    }
  };

  const footerContent = (
    <div>
      <Button
        label="Kanseller"
        icon="pi pi-check"
        onClick={handleClose}
        autoFocus
      />
      <Button
        label="Opprett"
        icon="pi pi-check"
        onClick={handleCreateUser}
        autoFocus
      />
    </div>
  );

  return (
    <div>
      <Button onClick={handleOpen} icon={<AddIcon></AddIcon>}>
        Opprett bruker
      </Button>
      <Dialog
        visible={open}
        onHide={handleClose}
        header="Opprett bruker"
        footer={footerContent}
      >
        <span
          className="p-float-label"
          style={{ width: "100%", marginTop: 30 }}
        >
          <InputText
            id="subject"
            value={newUser.name}
            placeholder="Emne"
            style={{ width: "100%" }}
            onChange={(e) =>
              setNewUser((prev) => ({ ...prev, name: e.target.value }))
            }
          />
          <label htmlFor="subject">Navn</label>
        </span>
        <span
          className="p-float-label"
          style={{ width: "100%", marginTop: 30 }}
        >
          <InputText
            id="subject"
            value={newUser.email}
            placeholder="Epost"
            style={{ width: "100%" }}
            onChange={(e) =>
              setNewUser((prev) => ({ ...prev, email: e.target.value }))
            }
          />
          <label htmlFor="subject">Epost</label>
        </span>

        <Allergies
          initialAllergies={newUser.allergies || ""}
          onChange={(allergies) => {
            console.log(allergies);
            setNewUser((prev) => ({ ...prev, allergies: allergies }));
          }}
        ></Allergies>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 3,
            marginTop: 30,
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              inputId="notifications"
              name="Epost varsler"
              value={newUser.allowNotifications}
              onChange={(e) =>
                setNewUser((prev) => ({
                  ...prev,
                  allowNotifications: e.checked || false,
                }))
              }
              checked={newUser.allowNotifications}
            />
            <label htmlFor={"notifications"} style={{ marginLeft: 5 }}>
              Epost varsler
            </label>
          </div>

          <div style={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              inputId="admin"
              name="admin"
              value={newUser.isAdmin}
              onChange={(e) =>
                setNewUser((prev) => ({
                  ...prev,
                  isAdmin: e.checked || false,
                }))
              }
              checked={newUser.isAdmin}
            />
            <label htmlFor={"admin"} style={{ marginLeft: 5 }}>
              Admin
            </label>
          </div>
        </div>
      </Dialog>
    </div>
  );
};
