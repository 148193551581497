import React, { useContext, useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import {
  Column,
  ColumnBodyOptions,
  ColumnEditorOptions,
} from "primereact/column";
import { IUser } from "../../Types/AuthTypes";
import axiosInstance from "../../Axios";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import { CreateOrUpdateUserDialog } from "../../Components/CreateOrUpdateUserDialog";
import Moment from "react-moment";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ToastContext from "../../Contexts/ToastContext";
import { Invitation } from "../../Types/Invitation";

export const UsersPage: React.FC = () => {
  const [users, setUsers] = useState<IUser[]>([]);
  const [userToDelete, setUserToDelete] = useState<IUser | null>(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const { showToast } = useContext(ToastContext);

  useEffect(() => {
    const getUsers = async () => {
      const response = await axiosInstance.get<IUser[]>("/admin/users");
      setUsers(response?.data);
    };
    getUsers();
  }, []);

  const textEditor = (options: ColumnEditorOptions) => (
    <InputText
      type="text"
      value={options.value}
      onChange={(e) =>
        options.editorCallback && options.editorCallback(e.target.value)
      }
    />
  );

  const boolEditor = (options: ColumnEditorOptions) => (
    <Checkbox
      checked={options.value}
      onChange={(e) =>
        options.editorCallback && options.editorCallback(e.checked)
      }
    />
  );

  const boolViewer = (data: IUser, options: ColumnBodyOptions) => (
    <Checkbox
      disabled
      checked={data[options.field as keyof IUser] as boolean}
      style={{ cursor: "default" }}
    />
  );

  const dateViewer = (data: IUser, options: ColumnBodyOptions) => {
    if (!data[options.field as keyof IUser]) return <></>;
    const date = new Date(data[options.field as keyof IUser] as string);

    // return <div>{moment(date).format("DD.MM.YYYY HH:MI:SS")}</div>;
    return <Moment format="DD.MM.YYYY HH:MI">{date}</Moment>;
  };

  const onSaveRow = async (userId: string, data: IUser) => {
    try {
      const newUser = await axiosInstance.put(
        `/admin/users/${data.rowKey}`,
        data
      );
      setUsers((prevState) => {
        const index = prevState.findIndex((user) => user.rowKey === userId);
        const updatedUsers = [...prevState];
        updatedUsers[index] = newUser.data;
        return updatedUsers;
      });
    } catch (error) {
      console.error(error);
    }
  };

  const deleteUser = async (event: React.MouseEvent<HTMLButtonElement>) => {
    try {
      await axiosInstance.delete(`/admin/users/${userToDelete?.rowKey}`);
      setUsers((prevState) =>
        prevState.filter((user) => user.rowKey !== userToDelete?.rowKey)
      );
    } catch (error) {
      console.error(error);
    }
  };

  const confirmDelete = (data: IUser) => {
    setUserToDelete(data);
    setShowDeleteDialog(true);
  };

  const createInvitation = async (invitation: Invitation) => {
    try {
      const createdInvitation = await axiosInstance.post<Invitation>(
        "/admin/invitations",
        invitation
      );

      createdInvitation.data.url = `${window.location.origin}/signup/${createdInvitation.data.invitationString}`;

      setUsers(
        (prev) =>
          [
            ...prev.filter((i) => i.rowKey !== invitation.intendedForUser),
            {
              ...prev.find((i) => i.rowKey === invitation.intendedForUser),
              invitation: createdInvitation.data,
            },
          ] as IUser[]
      );

      return createdInvitation.data;
    } catch (error) {
      console.error(error);
    }
  };

  const copyInvitationToClipboard = (invitation: Invitation) => {
    navigator.clipboard.writeText(
      `${window.location.origin}/signup/${invitation.invitationString}`
    );
    showToast({
      severity: "success",
      summary: "Invitasjon kopiert",
    });
  };

  const actionBodyTemplate = (data: IUser, options: ColumnBodyOptions) => {
    console.log(data, options);
    return (
      <>
        <ContentCopyIcon
          titleAccess="Kopier invitasjonslink"
          style={{
            cursor: "pointer",
          }}
          onClick={() =>
            data.invitation?.invitationString && !data.signupDate
              ? copyInvitationToClipboard(data.invitation)
              : showToast({
                  severity: "warn",
                  life: 0,
                  summary: data.signupDate
                    ? "Invitasjonen er brukt"
                    : "Ingen invitasjon tilgjengelig",
                  detail: data.signupDate ? (
                    `${data.name} har allerede brukt denne invitasjonen`
                  ) : (
                    <div>
                      <p>Ingen invitasjon tilgjengelig for {data.name}</p>
                      <Button
                        onClick={async () => {
                          const invitation = await createInvitation({
                            intendedForUser: data.rowKey,
                          } as Invitation);

                          invitation && copyInvitationToClipboard(invitation);
                        }}
                      >
                        Opprett invitasjon
                      </Button>
                    </div>
                  ),
                })
          }
        ></ContentCopyIcon>
        {options.rowEditor?.editing ? (
          <>
            <SaveIcon
              titleAccess="Lagre endringer"
              color="success"
              style={{ cursor: "pointer" }}
              onClick={(event) =>
                options.rowEditor?.onSaveClick &&
                options.rowEditor.onSaveClick(event)
              }
            ></SaveIcon>
            <ClearIcon
              titleAccess="Kanseller endringer"
              color="secondary"
              style={{ cursor: "pointer" }}
              onClick={(event) =>
                options.rowEditor?.onCancelClick &&
                options.rowEditor.onCancelClick(event)
              }
            ></ClearIcon>
          </>
        ) : (
          <EditIcon
            titleAccess="Rediger bruker"
            color="primary"
            style={{ cursor: "pointer" }}
            onClick={(event) =>
              options.rowEditor?.onInitClick &&
              options.rowEditor.onInitClick(event)
            }
          ></EditIcon>
        )}

        <DeleteIcon
          titleAccess="Slett bruker"
          color="error"
          style={{ cursor: "pointer" }}
          onClick={() => confirmDelete(data)}
        ></DeleteIcon>
      </>
    );
  };

  const deleteProductDialogFooter = (
    <>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={() => {
          setShowDeleteDialog(false);
          setUserToDelete(null);
        }}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deleteUser}
      />
    </>
  );

  const rowClass = (data: IUser) => {
    return {
      "bg-primary": !data.signupDate,
    };
  };

  return (
    <div>
      <CreateOrUpdateUserDialog
        onUserChanged={(user) => setUsers((prev) => [user].concat(prev))}
      ></CreateOrUpdateUserDialog>
      <DataTable
        value={users}
        sortField="name"
        sortOrder={1}
        stripedRows
        removableSort
        resizableColumns
        rowClassName={rowClass}
        filters={{
          name: { value: null, matchMode: FilterMatchMode.CONTAINS },
          email: { value: null, matchMode: FilterMatchMode.CONTAINS },
        }}
        tableStyle={{ minWidth: "50rem" }}
        dataKey="rowKey"
        filterDisplay="row"
        editMode="row"
        onRowEditComplete={(e) => console.log(e, "complete")}
        onRowEditSave={(e) => onSaveRow(e.data.rowKey, e.newData)}
      >
        <Column field="rowKey" header="Id"></Column>
        <Column
          field="name"
          header="Navn"
          sortable
          filter
          filterPlaceholder="Søk etter navn"
          editor={(options) => textEditor(options)}
        ></Column>
        <Column
          field="email"
          header="Epost"
          sortable
          filter
          filterPlaceholder="Søk etter epost"
          editor={(options) => textEditor(options)}
        ></Column>
        <Column
          field="allowNotifications"
          header="Varsler?"
          dataType="bool"
          sortable
          editor={(options) => boolEditor(options)}
          body={(data, options) => boolViewer(data, options)}
        ></Column>
        <Column
          field="lastLoginDate"
          header="Siste innlogging"
          sortable
          body={(data, options) => dateViewer(data, options)}
        ></Column>
        <Column
          field="loginProvider"
          header="Innloggingstype"
          sortable
          filter
          filterPlaceholder="Søk etter innloggingstype"
        ></Column>
        <Column
          field="isAdmin"
          header="Admin?"
          dataType="boolean"
          sortable
          editor={(options) => boolEditor(options)}
          body={(data, options) => boolViewer(data, options)}
        ></Column>
        <Column
          rowEditor={true}
          body={actionBodyTemplate}
          exportable={false}
          style={{ minWidth: "12rem" }}
        ></Column>
      </DataTable>{" "}
      <Dialog
        visible={showDeleteDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={deleteProductDialogFooter}
        onHide={() => setShowDeleteDialog(false)}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {userToDelete && (
            <span>
              Are you sure you want to delete <b>{userToDelete.name}</b>?
            </span>
          )}
        </div>
      </Dialog>
    </div>
  );
};
