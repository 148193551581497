import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import {
  Column,
  ColumnBodyOptions,
  ColumnEditorOptions,
} from "primereact/column";
import axiosInstance from "../../Axios";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import { Invitation } from "../../Types/Invitation";
import AddIcon from "@mui/icons-material/Add";
import { Calendar } from "primereact/calendar";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { IUser } from "../../Types/AuthTypes";
import { Dropdown } from "primereact/dropdown";

export const InvitationsPage: React.FC = () => {
  const [invitations, setInvitations] = useState<Invitation[]>([]);
  const [invitationToDelete, setInvitationToDelete] =
    useState<Invitation | null>(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const [showCreateInvitationDialog, setShowCreateInvitationDialog] =
    useState<boolean>(false);
  const [users, setUsers] = useState<IUser[]>([]);
  const [selectedUser, setSelectedUser] = useState<IUser | null>(null);

  const [newInvitation, setNewInvitation] = useState<{
    intendedForUser: string;
    expirationDate?: Date;
  }>({ intendedForUser: "" });

  useEffect(() => {
    const getUsers = async () => {
      const response = await axiosInstance.get<IUser[]>("/admin/users");

      setUsers(response?.data);
    };
    getUsers();
  }, []);

  useEffect(() => {
    const getInvitations = async () => {
      const response = await axiosInstance.get<Invitation[]>(
        "/admin/invitations"
      );

      response.data.map((invitation) => {
        invitation.url = `${window.location.origin}/signup/${invitation.invitationString}`;
        invitation.intendedForUser =
          users.find((i) => i.rowKey === invitation.intendedForUser)?.name ||
          invitation.intendedForUser;
      });

      setInvitations(response?.data);
    };
    getInvitations();
  }, [users]);

  const textEditor = (options: ColumnEditorOptions) => (
    <InputText
      type="text"
      value={options.value}
      onChange={(e) =>
        options.editorCallback && options.editorCallback(e.target.value)
      }
    />
  );

  const textWithCopyIcon = (data: Invitation, options: ColumnBodyOptions) => (
    <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
      <p style={{ margin: 0 }}>{data.url}</p>
      <ContentCopyIcon
        titleAccess="Kopier invitasjonslink"
        style={{
          cursor: "pointer",
        }}
        onClick={() => data.url && navigator.clipboard.writeText(data.url)}
      ></ContentCopyIcon>
    </div>
  );

  const boolEditor = (options: ColumnEditorOptions) => (
    <Checkbox
      checked={options.value}
      onChange={(e) =>
        options.editorCallback && options.editorCallback(e.checked)
      }
    />
  );

  const boolViewer = (data: Invitation, options: ColumnBodyOptions) => (
    <Checkbox
      disabled
      checked={data[options.field as keyof Invitation] as boolean}
      style={{ cursor: "default" }}
    />
  );

  const onSaveRow = async (invitationId: string, data: Invitation) => {
    try {
      const newInvitation = await axiosInstance.put<Invitation>(
        `/admin/invitations/${data.rowKey}`,
        data
      );

      newInvitation.data.url = `${window.location.origin}/signup/${newInvitation.data.invitationString}`;

      setInvitations((prevState) => {
        const index = prevState.findIndex(
          (invitation) => invitation.rowKey === invitationId
        );
        const updatedInvitations = [...prevState];
        updatedInvitations[index] = newInvitation.data;
        return updatedInvitations;
      });
    } catch (error) {
      console.error(error);
    }
  };

  const deleteInvitation = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    try {
      await axiosInstance.delete(
        `/admin/invitations/${invitationToDelete?.rowKey}`
      );
      setInvitations((prevState) =>
        prevState.filter(
          (invitation) => invitation.rowKey !== invitationToDelete?.rowKey
        )
      );
      setShowDeleteDialog(false);
    } catch (error) {
      console.error(error);
    }
  };

  const confirmDelete = (data: Invitation) => {
    setInvitationToDelete(data);
    setShowDeleteDialog(true);
  };

  const createInvitation = async () => {
    try {
      const createdInvitation = await axiosInstance.post<Invitation>(
        "/admin/invitations",
        newInvitation
      );

      createdInvitation.data.url = `${window.location.origin}/signup/${createdInvitation.data.invitationString}`;

      setNewInvitation({ intendedForUser: "" });
      setInvitations((prevState) => [...prevState, createdInvitation.data]);
      setShowCreateInvitationDialog(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setNewInvitation((prev) => ({
      ...prev,
      intendedForUser: selectedUser?.rowKey || "",
    }));
  }, [selectedUser]);

  const actionBodyTemplate = (data: Invitation, options: ColumnBodyOptions) => {
    return (
      <React.Fragment>
        {options.rowEditor?.editing ? (
          <>
            <SaveIcon
              titleAccess="Lagre endringer"
              color="success"
              style={{ cursor: "pointer" }}
              onClick={(event) =>
                options.rowEditor?.onSaveClick &&
                options.rowEditor.onSaveClick(event)
              }
            ></SaveIcon>
            <ClearIcon
              titleAccess="Kanseller endringer"
              color="secondary"
              style={{ cursor: "pointer" }}
              onClick={(event) =>
                options.rowEditor?.onCancelClick &&
                options.rowEditor.onCancelClick(event)
              }
            ></ClearIcon>
          </>
        ) : (
          <EditIcon
            titleAccess="Rediger invitasjon"
            color="primary"
            style={{ cursor: "pointer" }}
            onClick={(event) =>
              options.rowEditor?.onInitClick &&
              options.rowEditor.onInitClick(event)
            }
          ></EditIcon>
        )}

        <DeleteIcon
          titleAccess="Slett invitasjon"
          color="error"
          style={{ cursor: "pointer" }}
          onClick={() => confirmDelete(data)}
        ></DeleteIcon>
      </React.Fragment>
    );
  };

  const deleteProductDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={() => {
          setShowDeleteDialog(false);
          setInvitationToDelete(null);
        }}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deleteInvitation}
      />
    </React.Fragment>
  );

  return (
    <div>
      <DataTable
        value={invitations}
        sortField="name"
        sortOrder={1}
        stripedRows
        removableSort
        header={
          <div style={{ display: "flex", width: "100%" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                width: "fit-content",
                backgroundColor: "#f9fafb",
                borderRadius: 20,
                padding: 3,
              }}
              onClick={() => setShowCreateInvitationDialog(true)}
            >
              <AddIcon></AddIcon>
              <p style={{ margin: 0 }}>Opprett ny invitasjon</p>
            </div>
          </div>
        }
        resizableColumns
        filters={{
          name: { value: null, matchMode: FilterMatchMode.CONTAINS },
          email: { value: null, matchMode: FilterMatchMode.CONTAINS },
        }}
        tableStyle={{ minWidth: "50rem" }}
        dataKey="rowKey"
        filterDisplay="row"
        editMode="row"
        onRowEditComplete={(e) => console.log(e, "complete")}
        onRowEditSave={(e) => onSaveRow(e.data.rowKey, e.newData)}
      >
        <Column
          field="url"
          header="Invitasjonslink"
          sortable
          body={textWithCopyIcon}
        ></Column>
        <Column
          field="isUsed"
          header="Brukt?"
          dataType="bool"
          sortable
          editor={boolEditor}
          body={boolViewer}
        ></Column>
        <Column
          field="authProviderUsed"
          header="Innloggingstype"
          sortable
        ></Column>
        <Column field="usedByUser" header="Brukt av" sortable></Column>
        <Column field="intendedForUser" header="Sendt til" sortable></Column>
        <Column
          rowEditor={true}
          body={actionBodyTemplate}
          exportable={false}
          style={{ minWidth: "12rem" }}
        ></Column>
      </DataTable>{" "}
      <Dialog
        visible={showDeleteDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={deleteProductDialogFooter}
        onHide={() => setShowDeleteDialog(false)}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {invitationToDelete && (
            <span>
              Are you sure you want to delete <b>{invitationToDelete.rowKey}</b>
              ?
            </span>
          )}
        </div>
      </Dialog>
      <Dialog
        visible={showCreateInvitationDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Opprett invitasjon"
        modal
        footer={<Button onClick={() => createInvitation()}>Opprett</Button>}
        onHide={() => setShowCreateInvitationDialog(false)}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <label htmlFor="name">Tiltenkt person</label>
          {/* <InputText
            id="name"
            value={newInvitation?.intendedForUser || ""}
            placeholder="Tiltenkt person"
            onChange={(event) =>
              setNewInvitation((prev) => ({
                ...prev,
                intendedForUser: event.target.value,
              }))
            }
          ></InputText> */}
          <Dropdown
            value={selectedUser}
            onChange={(e) => setSelectedUser(e.value)}
            options={[{ name: "", rowKey: "" }, ...users]}
            optionLabel="name"
            placeholder="Select a Country"
            filter
            // valueTemplate={selectedCountryTemplate}
            // itemTemplate={countryOptionTemplate}
            className="w-full md:w-14rem"
          />
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <label htmlFor="name">Varighet invitasjon</label>
          <Calendar
            showIcon
            value={newInvitation.expirationDate}
            onChange={(e) =>
              setNewInvitation((prev) => ({
                ...prev,
                expirationDate: e.value as Date,
              }))
            }
          ></Calendar>
        </div>
      </Dialog>
    </div>
  );
};
