import { LatLngExpression } from "leaflet";
import React, { useEffect, useRef, useState } from "react";
import { Map } from "../../../Components/Map";
import { Polyline } from "react-leaflet";
import { useLocation } from "react-router-dom";

type Props = {
  markers?:
    | {
        position: LatLngExpression;
        label?: string | undefined;
      }[];
  minutesForTerrain?: number;
};

export type CoordinatePosition = {
  latitude: null | number;
  longitude: null | number;
};

export const EasterMap: React.FC<Props> = ({
  markers,
  minutesForTerrain = 10,
}) => {
  const [showTerrain, setShowTerrain] = useState<boolean>(false);
  const { search } = useLocation();
  const queryShowTerrain = new URLSearchParams(search).get("terrain");

  useEffect(() => {
    const interval = setInterval(() => {
      setShowTerrain(true);
    }, minutesForTerrain * 60000);

    return () => {
      clearInterval(interval);
    };
  }, [minutesForTerrain]);

  // const [distance, setDistance] = useState(0);
  // const [position, setPosition] = useState<CoordinatePosition | null>(null);
  // const [manualPosition, setManualPosition] =
  //   useState<CoordinatePosition | null>(null);

  // const [targetPosition, setTargetPosition] =
  //   useState<CoordinatePosition | null>(null);

  // const initial = useRef(true);

  // const [distanceHistory, setDistanceHistory] = useState<
  //   { distance: number; coordinates: CoordinatePosition }[]
  // >([]);

  // const [isUpdating, setIsUpdating] = useState(false);

  // const getDistanceFromLatLonInMeters = (
  //   lat1: number,
  //   lon1: number,
  //   lat2: number,
  //   lon2: number
  // ) => {
  //   var R = 6371; // Radius of the earth in km
  //   var dLat = deg2rad(lat2 - lat1); // deg2rad below
  //   var dLon = deg2rad(lon2 - lon1);
  //   var a =
  //     Math.sin(dLat / 2) * Math.sin(dLat / 2) +
  //     Math.cos(deg2rad(lat1)) *
  //       Math.cos(deg2rad(lat2)) *
  //       Math.sin(dLon / 2) *
  //       Math.sin(dLon / 2);
  //   var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  //   var d = R * c; // Distance in km
  //   return Math.round(d * 1000);
  // };

  // const getLocation = () => {
  //   if ("geolocation" in navigator) {
  //     if (initial.current) {
  //       navigator.geolocation.watchPosition((pos) => {
  //         setPosition({
  //           latitude: pos.coords.latitude,
  //           longitude: pos.coords.longitude,
  //         });
  //         setIsUpdating(true);
  //         console.log("new position", pos.coords);
  //         setTimeout(() => {
  //           setIsUpdating(false);
  //         }, 3000);
  //       });
  //       initial.current = false;
  //     }
  //     navigator.geolocation.getCurrentPosition(function (position) {
  //       console.log(position.coords.latitude, position.coords.longitude);
  //       setManualPosition({
  //         latitude: position.coords.latitude,
  //         longitude: position.coords.longitude,
  //       });
  //     });
  //   } else {
  //     console.log("Geolocation is not available in your browser.");
  //   }

  //   setTimeout(() => {
  //     setIsUpdating(false);
  //   }, 500);
  // };

  // useEffect(() => {
  //   getLocation();
  //   const timeout = setInterval(() => {
  //     setIsUpdating(true);
  //     getLocation();
  //   }, 1000);

  //   return () => {
  //     clearInterval(timeout);
  //   };
  // }, []);

  // const deg2rad = (deg: number) => {
  //   return deg * (Math.PI / 180);
  // };

  // useEffect(() => {
  //   if (
  //     distance !== 0 &&
  //     position?.latitude !==
  //       distanceHistory[distanceHistory.length]?.coordinates?.latitude &&
  //     position?.longitude !==
  //       distanceHistory[distanceHistory.length]?.coordinates?.longitude
  //   )
  //     setDistanceHistory([
  //       ...distanceHistory,
  //       { distance: distance, coordinates: position as CoordinatePosition },
  //     ]);
  // }, [distance]);

  // useEffect(() => {
  //   if (targetPosition && position) {
  //     setDistance(
  //       getDistanceFromLatLonInMeters(
  //         position.latitude as number,
  //         position.longitude as number,
  //         targetPosition.latitude as number,
  //         targetPosition.longitude as number
  //       )
  //     );
  //   }
  // }, [targetPosition, position]);

  // useEffect(() => {
  //   if (target) setTargetPosition(target);
  // }, [target]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
      className="leaflet-container-hotcold"
    >
      <Map
        centerLocation={[59.14705054600465, 6.076540139602003]}
        zoom={17}
        terrain={
          showTerrain ||
          (queryShowTerrain !== null && queryShowTerrain !== undefined)
        }
        // centerLocation={[59.1475107, 6.0762491]}
        // showLayer={false}

        markers={markers}
        //   {
        //     position: [
        //       position?.latitude as number,
        //       position?.longitude as number,
        //     ],
        //     label: "You",
        //   },
        //   {
        //     position: [
        //       manualPosition?.latitude as number,
        //       manualPosition?.longitude as number,
        //     ],
        //     label: `Manual you, Lat: ${manualPosition?.latitude}, Lng: ${manualPosition?.longitude}`,
        //   },
        // ]}
        // showLayer={false}
      ></Map>
    </div>
  );
};
