export const BridalParty: React.FC = () => {
  return (
    <div
      style={{
        textAlign: "center",
        border: "1px solid black",
        borderRadius: 20,
        position: "relative", // Establish this div as the reference for absolute positioning
        overflow: "hidden", // Hide overflow to ensure the shape doesn't display outside this container
      }}
    >
      <div
        style={{
          position: "absolute",
          top: -112, // Position the top edge 112px above the container's top edge
          right: -112, // Position the right edge 112px beyond the container's right edge
          backgroundColor: "#CFB0958C",
          height: 312,
          width: 312,
          borderRadius: "50%",
          zIndex: -1,
          display: "inline",
        }}
      ></div>
      <h1>VÅRT BRUDEFØLGE</h1>
      <h2>Tamiras forlovere</h2>
      <p>Cecilie Hetland</p>
      <p>Dina Danielsen</p>
      <h2>Sondres forlovere</h2>
      <p>Espen Handeland</p>
      <p>Ukjent</p>
      <div style={{ paddingLeft: 40, paddingRight: 40 }}>
        <div style={{ height: 2, backgroundColor: "black" }}></div>
      </div>
      <h2>Fotograf</h2>
      <p>Katrina Haver</p>
      <h2>Toastmaster</h2>
      <p>Ukjent</p>
      <div
        style={{
          position: "absolute",
          left: -112, // Position the top edge 112px above the container's top edge
          bottom: -112, // Position the right edge 112px beyond the container's right edge
          backgroundColor: "#CFB0958C",
          height: 312,
          width: 312,
          borderRadius: "50%",
          zIndex: -1,
          display: "inline",
        }}
      ></div>
    </div>
  );
};
